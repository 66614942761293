
const state = {
	settings: []
};

const getters = {
	get: (state) => ({ list, key, def }) => {
		let row = state.settings.find(e => e.list === list && e.key === key);
		return row ? row.value : def;
	},
};

const mutations = {
	set(state, { list, key, value }) {
		let row = state.settings.find(e => e.list === list && e.key === key);
		if (row) {
			row.value = value;
		} else {
			state.settings.push({
				list, key, value
			});
		}
	},
	reset(state) {
		state.settings = [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
