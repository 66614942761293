import Vue from 'vue';
import moment from 'moment';
import Store from '@/store/index.js';
import { i18n } from './i18n';
import * as Common from 'tdbcommon';

/*
 * common filters that can be used in vue-templates as 'var | filter'
 * or used directly in code if exported here
 */

export function toDate(dateString) {
	return moment(dateString);
}

export function toTimeFormat(date) {
	if (!date) return '';
	const format = Store.state.base.userTimeFormat;
	return moment(date).format(format);
}

export function toDateFormat(date) {
	if (!date) return '';
	const locale = window.navigator.language;
	return moment(date).locale(locale).format('L');
}

export function toDateTimeFormat(obj) {
	if (!obj) return '';
	const format = `L ${Store.state.base.userTimeFormat}`;
	const locale = window.navigator.language;
	return moment(obj).locale(locale).format(format);
}

export function toDayMonth(date) {
	if (!date) return '';
	return moment(date).format('DD.MM.');
}

export function toCalendarWeek(date) {
	if (!date) return '';
	return moment(date).isoWeek();
}
export function toCalendarWeekFromTo(date) {
	if (!date) return '';
	let locale = window.navigator.language;
	let d = moment(date);
	let from = d.startOf('isoWeek').locale(locale).format('L');
	let to = d.endOf('isoWeek').locale(locale).format('L');
	return `${from} - ${to}`;
}

export function toAccountAddress(obj) {
	let data = obj.data || obj;
	if (data.userData?.address) {
		return data.userData.address;
	}
	if (data.userData?.contact) {
		return data.userData.contact;
	}
	if (data.userData?.supData?.length) {
		let sd = data.userData.supData.find(c => c.country === Store.state.country.currentCountry.id);
		if (sd) {
			let result = sd.locationsOfSale.find(los => los.default);
			result = result || sd.locationsOfSale[0];
			return result.address;
		}
	}
	return null;
}

export function toAccountName(obj) {
	let data = obj.data || obj;
	if (data.staticData?.name) {
		return data.staticData.name;
	}
	if (data.userData?.address?.name) {
		return data.userData.address.name;
	}
	if (data.userData?.contact?.name) {
		return data.userData.contact.name;
	}
	if (data.userData?.controlNumber) {
		return data.userData.controlNumber;
	}
	return toLosName(data);
}

export function idToAccountName(id, list) {
	let found = list.find(e => e.id === id);
	return found ? toAccountName(found) : '?';
}

export function toLosName(obj) {
	let data = obj.data || obj;
	if (data.userData?.supData?.length) {
		let sd = data.userData.supData.find(c => c.counrty === Store.state.country.currentCountry.id);
		if (!sd) sd = data.userData.supData[0];
		let result = sd.locationsOfSale.find(los => los.default);
		result = result || sd.locationsOfSale[0];
		return result.address?.name || i18n.t('menu.noname');
	}
	// console.warn('unable to find name in ', JSON.stringify(obj));
	return i18n.t('menu.noname');
}

export function idToLosName(id, list) {
	let found = list.find(e => e.id === id);
	return found ? toLosName(found) : '?';
}

Vue.filter('dateFormat', toDateFormat);
Vue.filter('timeFormat', toTimeFormat);
Vue.filter('dateTimeFormat', toDateTimeFormat);
Vue.filter('dayMonth', toDayMonth);
Vue.filter('calendarWeek', toCalendarWeek);
Vue.filter('calendarWeekFromTo', toCalendarWeekFromTo);
Vue.filter('toAccountName', toAccountName);
Vue.filter('idToAccountName', (id, list) => {
	if (Array.isArray(id)) {
		let labels = id.map(e => {
			return idToAccountName(e, list);
		});
		if (labels.length === 0) {
			return '---';
		}
		return labels.join(', ');
	}
	return idToAccountName(id, list);
});

export function toLocaleNoFallbackIfExists(obj) {
	if (!obj) return null;
	let r = toLocaleNoFallback(obj);
	if (r === '---') return '';
	return r;
}
export function toLocaleIfExists(obj) {
	if (!obj) return null;
	let r = toLocale(obj);
	if (r === '---') return '';
	return r;
}
export function toLocaleNoFallback(obj) {
	if (!obj) return '---';
	if (typeof obj === 'string') return obj;
	if (typeof obj === 'undefined') return '---';
	if (typeof obj === 'boolean') return booleanDisplay(obj);
	if (!Array.isArray(obj)) return '-?-';
	let best = getLocaleEntry(obj, Store.state.base.uiLocale);
	if (best) return best;
	return '---';
}
export function toLocale(obj) {
	if (!obj) return '---';
	if (typeof obj === 'string') return obj;
	if (typeof obj === 'undefined') return '---';
	if (typeof obj === 'boolean') return booleanDisplay(obj);
	if (!Array.isArray(obj)) return '-?-';
	let toCheck = Common.Config.Languages.getLanguagePriority(Store.state.base.uiLocale, Store.state.base.fallbackLocale);
	for (let lang of toCheck) {
		let val = getLocaleEntry(obj, lang);
		if (val) return val;
	}
	if (obj.length > 0) return obj[0].label;
	return '---';
}
function getLocaleEntry(arr, lang) {
	let entry = arr.find(e => e.lang === lang);
	return entry ? entry.label : null;
}

Vue.filter('toLocaleIfExists', toLocaleIfExists);
Vue.filter('toLocale', toLocale);

export function booleanDisplay(value) {
	return i18n.t(value ? 'default.common.yes' : 'default.common.no');
}

Vue.filter('toBooleanDisplay', booleanDisplay);

Vue.filter('toNumberFormat', val => {
	if (typeof val !== 'number') return val;
	let formatter = new Intl.NumberFormat(Store.state.base.uiLocale.replace('_', '-'), {
		style: 'percent',
		minimumFractionDigits: 3
	});
	return formatter.format(val / 100.0);
});

Vue.filter('idToLabel', (id, list) => {
	if (Array.isArray(id)) {
		let labels = id.map(e => {
			return idToLabel(e, list);
		});
		if (labels.length === 0) {
			return '---';
		}
		return labels.join(', ');
	}
	return idToLabel(id, list);
});

export function idToLabel(id, list) {
	let found = list.find(e => e.id === id);
	return found ? toLocale(found.data.labels) : '?';
}

export function formatHid(hid) {
	let b = `${hid}`;
	return `${b.substr(0, 3)}-${b.substr(3, 3)}-${b.substr(6, 4)}`;
}

Vue.filter('hid', formatHid);

export function parseHid(string) {
	let ret = string.replace(/\D/gu, '');
	if (ret) return parseInt(ret.substring(0, 10));
	return '';
}
