import Store from '@/store/main.js';

Store.watch(
	() => Store.getters['auth/token/account'],
	() => {
		Store.commit('offer/search/resetAllFilters');
		Store.commit('request/main/reset');
		Store.commit('offer/search/resetMode');
		Store.commit('list/reset');
		Store.commit('request/view/setExportType', '');
	}
);
