<template>
	<b-jumbotron
		:header="$t('countrySelection.tdb')"
		class="m-5"
	>
		<Notifications />
		<LoadingIndicator />
		<p>{{ $t('countrySelection.select') }}</p>
		<b-list-group>
			<b-list-group-item
				v-for="country in countries"
				:key="country.id"
			>
				<b-link
					@click="select(country)"
				>
					{{ country.data.labels | toLocale }}
				</b-link>
			</b-list-group-item>
		</b-list-group>
	</b-jumbotron>
</template>

<script>
import LoadingIndicator from './loading-indicator.vue';
import Notifications from './notifications.vue';
/*
 * selection of country
 */
export default {
	name: 'CountrySelectionLayout',
	components: {
		LoadingIndicator,
		Notifications
	},
	computed: {
		countries() {
			return this.$store.getters['country/countriesForSelection'];
		}
	},
	watch: {
		countries(nv) {
			if (nv.length === 1) {
				this.select(nv[0]);
			}
		}
	},
	methods: {
		getBestUrl(country) {
			let list = country.data.domains;
			let current = document.location.hostname;
			let filtered = list.filter(e => e.includes(current));
			let base = filtered.length > 0 ? filtered[0] : list[0];
			if (!base) return '';
			return `${document.location.protocol}//${base}${document.location.hash}`;
		},
		select(country) {
			let url = this.getBestUrl(country);
			console.log(country, url);
			window.location.href = url;
		}
	}
};
</script>

<style scoped lang="scss">

</style>
