import EventBus from '@/util/eventbus.js';
import { setToken } from './jwt-cache.js';
import { AdminParty, CommitSha } from './env.js';
import store from '@/store';
import Vue from 'vue';
import { i18n } from '@/plugins/i18n';
import { log } from './request';
import * as LocalStorage from '@/repository/localStorage.js';

function checkForMaintenance(e) {
	if (!e || !e.message) return false;
	if (e.message.includes('service is currently not available. check back later') || e.message.includes('Failed to fetch')) {
		store.commit('base/setIsMaintenance');
		return true;
	}
	return false;
}

function getExtraLogData() {
	return {
		ua: navigator.userAgent,
		lang: navigator.language,
		plat: navigator.platform,
		loc: window.location.href,
		sx: screen.width,
		sy: screen.height,
		app: 'tdb',
		build: CommitSha
	};
}

async function logError(data) {
	try {
		await log({ ...data, ...getExtraLogData() });
	} catch (e) {
		console.log('error during error logging', e);
	}
}

export async function initialize() {

	window.onerror = function(message, source, lineno, colno, error) {
		console.log('window.onerror', error);
		EventBus.$emit('error', error);
		let maintenance = checkForMaintenance(error);
		if (!maintenance) {
			logError({ message, source, lineno, colno, error });
		}
	};

	// notification handling
	EventBus.$on('error', (error, title) => {
		store.commit('notification/add', {
			type: 'error',
			title: title || 'Error',
			body: error.message ? error.message : JSON.stringify(error),
			time: Date.now(),
		});
		console.log(error);
	});
	EventBus.$on('warning', (title, body) => {
		store.commit('notification/add', {
			type: 'warning',
			title,
			body,
			time: Date.now(),
		});
		console.log(title, '|', body);
	});
	EventBus.$on('message', (title, body) => {
		store.commit('notification/add', {
			type: 'message',
			title,
			body,
			time: Date.now(),
		});
		console.log(title, '|', body);
	});
	EventBus.$on('success', (title, body) => {
		store.commit('notification/add', {
			type: 'success',
			title,
			body,
			time: Date.now(),
		});
		console.log(title, '|', body);
	});

	// error handling
	if (process.env.NODE_ENV === 'production') {
		Vue.config.errorHandler = function(error) {
			checkForMaintenance(error);
			console.log('vue.errorHandler', error);
			EventBus.$emit('error', error);
		};
		Vue.config.warnHandler = function(error) {
			checkForMaintenance(error);
			console.log('vue.warnHandler', error);
			EventBus.$emit('warning', error);
		};
		window.addEventListener('unhandledrejection', event => {
			checkForMaintenance(event.reason);
		});
	}


	if (!AdminParty && store.getters['auth/token/isLoggedIn']) {
		setToken(store.getters['auth/token/currentToken']);
		store.dispatch('auth/user/loadAvailableAccounts');
		store.dispatch('auth/user/loadAccountData');
	}


	store.dispatch('auth/token/initApp').catch(err => console.log(err));
	i18n.locale = store.state.base.uiLocale;
	try {
		await store.dispatch('country/init');
		await store.dispatch('i18n/loadLanguage', { language: store.state.base.uiLocale });
	} catch (e) {
		if (!checkForMaintenance(e)) throw e;
	}

	LocalStorage.cleanRequestFormDrafts();

	document.title = i18n.t('document.title');
}
