<template>
	<b-container fluid class="no-padding">
		<div class="nav-wrapper">
			<b-container>
				<Notifications />
				<b-row no-gutters>
					<b-col cols="12">
						<MainNav />
					</b-col>
				</b-row>
			</b-container>
		</div>

		<LoadingIndicator />
		<router-view />

		<MainFooter />
	</b-container>
</template>

<script>
import MainNav from './main-nav.vue';
import LoadingIndicator from './loading-indicator.vue';
import Notifications from './notifications.vue';
import MainFooter from './main-footer.vue';
/*
 * just the main layout and it's default parts
 * so that layout can change and app.vue is not overloaded
 */
export default {
	name: 'DefaultLayout',
	components: {
		MainNav,
		LoadingIndicator,
		Notifications,
		MainFooter
	}
};
</script>

<style scoped lang="scss">

</style>
