import jwtDecode from 'jwt-decode';
import { DOMAIN, KeycloakInfo } from '@/util/env.js';
import { setToken } from '@/util/jwt-cache.js';
import { loginToDomain } from '@/repository/auth/account.js';
import Keycloak from 'keycloak-js';
import * as LocalStorage from '@/repository/localStorage.js';

const keycloak = new Keycloak(KeycloakInfo);

let initPromise;
let refreshTimer;
let isInit = false;

const state = {
	main: {
		token: null,
		expires: 0,
		account: null
	},
	ssoToken: null,
	ssoRefreshToken: null,
	ssoParsed: null
};

const getters = {

	account: (state) => {
		return state.main.account;
	},

	isLoggedIn: (state) => {
		let ts = ~~(Date.now()/1000);
		return state.main.expires > ts
			&& state.main.token !== null;
	},

	currentToken: (state) => {
		return state.main.token;
	},

	hasAccountSelected: (state) => {
		return state.main.account !== null;
	},

	getValidTokenForAccount: (state) => (accountId) => {
		let ts = ~~(Date.now()/1000);
		if (state.main.account === accountId && state.main.expires > ts) {
			return state.main.token;
		}
		return null;
	},

	hasSsoLogin: (state) => {
		return state.ssoParsed && state.ssoParsed.exp > Date.now()/1000;
	},

	ssoProfileLink: () => keycloak.createAccountUrl(),

	ssoToken: (state) => state.ssoToken,

	ssoUserId: (state) => {
		return state.ssoParsed?.sub;
	}
};

const actions = {

	/**
	 * should be called once at the start
	 **/
	async initApp({ dispatch, commit, state }) {
		if (!initPromise) {
			let opts = {
				silentCheckSsoFallback: false,
				checkLoginIframe: false
			};
			if (state.ssoToken && state.ssoRefreshToken) {
				opts.idToken = opts.token = state.ssoToken;
				opts.refreshToken = state.ssoRefreshToken;
			}
			// console.log('keycloak init', opts);
			initPromise = keycloak.init(opts);
		}
		let auth = await initPromise;
		if (isInit) return;
		isInit = true;
		console.log('init', auth, keycloak);
		if (auth) {
			if (keycloak.refreshToken) {
				try {
					await keycloak.updateToken(65);
				} catch (e) {
					//refresh token expired
					console.log('refresh failed', e);
					return;
				}
			}
			await dispatch('authenticate');
		}
		if (!refreshTimer) {
			refreshTimer = setInterval(async() => {
				if (!keycloak.refreshToken) return;
				try {
					let refreshed = await keycloak.updateToken(65);
					if (refreshed) {
						commit('setSsoToken', keycloak);
						let data = await loginToDomain(state.ssoToken, state.main.account);
						commit('login', data.jwt);
					}
				} catch (e) {
					console.log('refresh token expired', e);
					//now what?
				}
			}, 1000 * 30);
		}
	},

	/**
	 * call to log in the user
	 **/
	async authenticate({ commit, dispatch, state, rootState, rootGetters }) {
		// console.log('authenticate', keycloak);
		if (!keycloak.token) {
			let locale = rootState.base.uiLocale.substr(0, 2);
			let country = rootGetters['country/currentCountry'];
			const idpHint = new URL(document.location.href).searchParams.get('idpHint') || country.data.ssoIdpHint;
			let params = {
				redirectUri: document.location.href,
				locale,
				idpHint
			};
			let url = await keycloak.createLoginUrl(params);
			window.location.href = url;
			return;
		}
		commit('setSsoToken', keycloak);
		let data = await loginToDomain(state.ssoToken, state.main.account);
		commit('login', data.jwt);
		await dispatch('auth/user/switchToFirstAvailableAccount', null, { root: true });
	},

	async register({ rootState, rootGetters }) {
		let locale = rootState.base.uiLocale.substr(0, 2);
		let country = rootGetters['country/currentCountry'];
		let params = {
			redirectUri: document.location.href,
			locale,
			idpHint: country.data.ssoIdpHint
		};
		let url = keycloak.createRegisterUrl(params);
		window.location.href = url;
	},

	/**
	 * call to log out the user
	 **/
	async logout({ commit }) {
		commit('logout');
		await keycloak.logout();
	},

	/**
	 * switch zdb accounts
	 **/
	async switchAccountAndLogin({ commit, dispatch, getters, state, rootGetters }, accountId) {
		// errors in this function are never displayed to user? not translated for now
		if (!accountId) throw new Error('no accountId specified');
		if (accountId === state.main.account) return;
		dispatch('redirect/runHook', { trigger: 'afterAccountSwitch' }, { root: true });
		let existing = getters.getValidTokenForAccount(accountId);
		if (existing) {
			commit('login', existing);
		} else {
			let data = await loginToDomain(state.ssoToken, accountId);
			if (!data) throw new Error('Could not switch account.');
			commit('login', data.jwt);
		}
		let country = rootGetters['country/currentCountry'].id;
		LocalStorage.setLastUsedAccountId(country, accountId);
		await dispatch('auth/user/loadAccountData', null, { root: true });
	}
};

const mutations = {

	login(state, jwt) {
		// errors in this function are never displayed to user? not translated for now
		let { aud, sub, exp } = jwtDecode(jwt);
		if (aud !== DOMAIN) {
			throw new Error(`token expected for ${DOMAIN} but received for ${aud}`);
		}
		let expDate = new Date(exp * 1000);
		if (expDate < new Date()) {
			throw new Error('token is expired');
		}
		if (!sub) sub = null;
		state.main.token = jwt;
		state.main.expires = exp;
		state.main.account = sub;
		setToken(jwt);
	},

	logout(state) {
		state.main.token = null;
		state.main.account = null;
		state.main.expires = 0;
		state.ssoToken = null;
		state.ssoParsed = null;
		setToken(null);
	},

	setSsoToken(state, { token, tokenParsed, refreshToken }) {
		state.ssoToken = token;
		state.ssoParsed = tokenParsed;
		state.ssoRefreshToken = refreshToken;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
