import Vue from 'vue';
import Vuex from 'vuex';
import { DOMAIN, ADMIN } from '../util/env';
import createPersistedState from 'vuex-persistedstate';

import base from './modules/base.js';
import admin from './modules/admin.js';
import upload from './modules/upload.js';
import notification from './modules/notification.js';
import auth from './modules/auth.js';
import country from './modules/country.js';
import offer from './modules/offer.js';
import related from './modules/related.js';
import location from './modules/location.js';
import page from './modules/page.js';
import region from './modules/region.js';
import request from './modules/request.js';
import bbay from './modules/bbay.js';
import mailer from './modules/mailer.js';
import list from './modules/list.js';
import redirect from './modules/redirect.js';
import i18n from './modules/i18n.js';

Vue.use(Vuex);

/* saves state in localStorage. path does not yet exist */
const persistence = createPersistedState({
	key: `${DOMAIN}_${ADMIN?'ADMIN':'USER'}_auth_locale`,
	storage: window.localStorage,
	// WARNING: getter on rehydrated properties are WRONG (do not update) -> use $store.state.[...]
	paths: ['auth.token', 'base.uiLocale', 'list'],
	// rehydrated(rehydratedState) {
	// }
});
const logger = store => {
	// called when the store is initialized
	store.subscribe((mutation) => {
		console.debug('mutation: ', mutation);
	});
};

const plugins = process.env.NODE_ENV === 'development'
	? [ persistence, logger ]
	: [ persistence ];

export default new Vuex.Store({
	strict: true,
	plugins,
	modules: {
		base,
		admin,
		upload,
		notification,
		auth,
		country,
		offer,
		related,
		location,
		page,
		region,
		request,
		mailer,
		redirect,
		list,
		i18n,
		bbay
	}
});
