import { get as _get, post } from '@/util/request.js';

export function create({ name, alias, paths, rules }) {
	return post('bbay/ruleset/create', { name, alias, paths, rules });
}

export function update({ id, name, paths, rules }) {
	return post('bbay/ruleset/update', { id, name, paths, rules });
}

export function get({ id, alias }) {
	if (id) return post('bbay/ruleset/get', { id });
	else if (alias) return post('bbay/ruleset/get', { alias });
	throw new Error('no id or alias');
}

export function list() {
	return _get('bbay/ruleset/list');
}

export function run({ ruleset, facts }) {
	return post('bbay/evaluator/run', { ruleset, facts });
}

export function test({ rules, facts }) {
	return post('bbay/evaluator/test', { rules, facts });
}
