import Vue from 'vue';
import VueRouter from 'vue-router';

import Store from '../store/index.js';
import EventHub from '@/util/eventbus.js';
import { EtrackerCode } from '@/util/env.js';

Vue.use(VueRouter);


let waitForUserLoaded = new Promise(res => {
	Store.watch(() => Store.getters['auth/user/isLoaded'], a => {
		if (a) res();
	});
});

export async function createRouter(prefix, admin) {
	let routes = [];
	if (admin) {
		let adminRoutes = await import(/* webpackChunkName: "admin" */ './admin.js');
		routes = adminRoutes.default;
	} else {
		routes = await Promise.all([
			import(/* webpackChunkName: "user" */ './guest.js'),
			import(/* webpackChunkName: "user" */ './user.js')
		]).then(list => {
			return list.flatMap(e => e.default);
		});
	}
	// console.log('routes', routes);
	const router = new VueRouter({
		routes,
		mode: 'history',
		base: prefix
	});
	router.beforeEach((to, from, next) => {
		if (!to.meta || !to.meta.roles) return next();
		return waitForUserLoaded.then(() => {
			for (let role of to.meta.roles) {
				if (Store.getters['auth/user/hasRole'](role)) return next();
			}
			return next({ name: 'Home' });
		});
	});
	router.beforeEach((to, from, next) => {
		EventHub.$emit('page.change', { from, to });
		return next();
	});
	router.afterEach((to) => {
		if (typeof _etracker === 'object') {
			// eslint-disable-next-line no-undef, camelcase
			if (EtrackerCode) et_eC_Wrapper({ et_et: EtrackerCode });
		}
	});
	return router;
}
