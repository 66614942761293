import LRU from 'lru-cache';
import * as ExportRepo from '@/repository/export';
import * as TdbRepo from '@/repository/tdb';
import * as DlHelper from '@/util/document-helper.js';
import i18n from '@/plugins/i18n.js';
import { Request } from 'tdbcommon';

const viewcache = new LRU({
	max: 10,
	maxAge: 1000 * 10
});

const requestStatusLabels = {
	'10000d95-7d41-48e8-ba20-2273dc7cbd5d': 'none',
	'200001e9-ff8c-468b-a68b-b44ca2fa7deb': 'archived'
};

const state = {
	exportRunning: false,
	exportType: ''
};

const getters = {
	availableStatusLabels: (s, g, rs, rg) => {
		let base = Object.entries(requestStatusLabels).map(([k, v]) => ({ value: k, text: i18n.t(`request.userStatus.${v}`) }));
		let user = rg['auth/user/details'];
		let folders = [];
		if (user.userData.requestFolders) {
			folders = user.userData.requestFolders.map(e => ({ value: e.id, text: e.label }));
		}
		return base.concat(folders);
	}
};

const actions = {

	setViewCache(_, { id, value }) {
		if (!value || !id) throw new Error('id and value must be set');
		if (!(value instanceof Request.RequestProcess)) {
			throw new Error('must be a RequestProcess class');
		}
		viewcache.set(id, value);
	},

	getViewCache(_, { id }) {
		return viewcache.get(id);
	},

	async updateExportType({ commit, rootGetters, dispatch }) {
		let rs = await dispatch('admin/getRulesetForKey', { key: 'exports' }, { root: true });
		if (!rs) return;
		let facts = await dispatch('bbay/evaluate', {
			id: rs.id,
			rev: rs.rev,
			facts: {
				env: {
					country: rootGetters['country/currentCountry'].id,
					role: rootGetters['auth/user/role'].toLowerCase(),
					user: rootGetters['auth/token/account']
				},
				export: 'requests-external'
			}
		}, { root: true });
		commit('setExportType', facts.export);
	},

	async runExport({ state, commit, rootState, dispatch }, { attributeFilter, language }) {
		if (state.exportRunning) throw new Error('export already running');
		try {
			commit('setRunning', true);
			if (!state.exportType) await dispatch('updateExportType');
			if (!language) language = rootState.base.uiLocale;
			let config = await ExportRepo.getConfig({ alias: state.exportType });
			let jobParams = {
				config: config.id,
				customColumnHeaders: [],
				runParams: {
					language,
					fallbackLanguage: rootState.base.fallbackLocale,
					tl: {
						'gender.mixed': i18n.t('offermanagement.search.mixed', language),
						'gender.female': i18n.t('offermanagement.search.female', language),
						'gender.male': i18n.t('offermanagement.search.male', language),
						'status.open': i18n.t('request.status.open', language),
						'status.closed': i18n.t('request.status.closed', language),
						'status.accepted': i18n.t('request.status.accepted', language),
						'status.rejected': i18n.t('request.status.rejected', language)
					}
				},
				searchFilter: attributeFilter
			};
			const cols = [
				'agrName',
				'agrStreet',
				'agrCitycode',
				'agrCity',
				'aut',
				'ins',
				'aso',
				'packageName',
				'status',
				'animalkind',
				'offergroup',
				'animalsRequired',
				'reason',
				'reason_text_agr',
				'reason_text_aut',
				'reason_text_ins',
				'reason_text_aso',
				'filterGender',
				'ageGroup',
				'category1',
				'category2',
				'createdDate',
				'updatedDate',
				'controlNumber',
				'hid',
				'agr_reason',
				'species_name',
				'vvvnr',
				'exception_1344',
				'animal_ident_method',
				'detailed_description',
				'current_animal_count',
				'animal_noneco_count_12mo',
				'main_answer_ins',
				'main_answer_aut',
				'reason_dehorning',
				'ear_tag_number',
				'weightClass',
				'access_date',
				'birth_date',
				'sperm_separation',
				'embryo_transfer'
			];
			cols.forEach(col => {
				let tl = i18n.t(`request.export.header.${col}`, language);
				let def = {
					key: col
				};
				if (tl && tl !== `request.export.header.${col}`) def.label = tl;
				jobParams.customColumnHeaders.push(def);
			});
			let job = await ExportRepo.createJob(jobParams);
			job = await ExportRepo.pollUntilJobComplete(job.id, 2000, 1000 * 60 * 30);
			let blob = DlHelper.dataUriToBlob(job.result);
			let fileName = `export.${config.exportFileType}`;
			DlHelper.downloadUpload(blob, fileName);
			commit('setRunning', false);
		} catch (e) {
			commit('setRunning', false);
			console.error(e);
			throw e;
		}
	},

	saveInProcess(_, { process, editorName, inProgress, statusLabel }) {
		return TdbRepo.setRequestStatus({ process, editorName, inProgress, statusLabel });
	},

};

const mutations = {
	setRunning(state, running) {
		state.exportRunning = running;
	},
	setExportType(state, type) {
		state.exportType = type;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
