import EventHub from '@/util/eventbus.js';

const state = {
	hooks: [{
		trigger: 'afterAccountSwitch',
		route: { name: 'Home' },
		onetime: false,
		surpressOnce: false
	}],
	knownTriggers: [
		'afterAccountSwitch',
		'afterRegistration',
		'afterProfileUpdate',
		'afterOfferTosAccept'
	]
};

const getters = {
	get: (state) => ({ list, key, def }) => {
		let row = state.settings.find(e => e.list === list && e.key === key);
		return row ? row.value : def;
	},
};

const actions = {
	runHook({ state, commit }, { trigger }) {
		if (!state.knownTriggers.includes(trigger)) throw new Error(`unknown trigger: ${trigger}`);
		let hook = state.hooks.find(e => e.trigger === trigger);
		console.log('runHook', { trigger, hook: hook ? JSON.parse(JSON.stringify(hook)): 'none' });
		if (!hook) return false;
		if (hook.surpressOnce) {
			commit('setHook', { trigger, surpressOnce: false });
			return false;
		}
		if (hook.onetime) {
			commit('removeHook', { trigger });
		}
		let route = hook.route;
		EventHub.$emit('navigate', route);
		return true;
	},
	surpressAccountSwitchHook({ commit }) {
		commit('setHook', { trigger: 'afterAccountSwitch', surpressOnce: true });
	}
};

const mutations = {
	setHook(state, params) {
		if (!state.knownTriggers.includes(params.trigger)) throw new Error(`unknown trigger: ${params.trigger}`);
		let existing = state.hooks.find(h => h.trigger === params.trigger);
		if (!existing) {
			if (!params.route) throw new Error('cannot create route hook, no route given');
			existing = params;
			state.hooks.push(existing);
		} else {
			['route', 'onetime', 'surpressOnce'].forEach(key => {
				if (typeof params[key] !== 'undefined') {
					existing[key] = params[key];
				}
			});
		}
	},
	removeHook(state, { trigger }) {
		if (!state.knownTriggers.includes(trigger)) throw new Error(`unknown trigger: ${trigger}`);
		state.hooks = state.hooks.filter(e => e.trigger !== trigger);
	},
	removeAllHooks(state) {
		state.hooks = [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
