import { post } from '@/util/request.js';
import { getToken } from '@/util/jwt-cache.js';

export function createAccount({ registrationName, requiresConfirmation, userData, staticData }) {
	return post('auth/accountmanage/createaccount', {
		registrationName, requiresConfirmation, userData, staticData
	});
}

export function updateAccount({ active, account, userData, staticData, maxUsers }) {
	return post('auth/accountmanage/updateaccount', {
		active, account, userData, staticData, maxUsers
	});
}

export function removeAccount({ account }) {
	return post('auth/accountmanage/removeaccount', {
		account
	});
}

export function unregisterAccount(account, ssoUser) {
	return post('auth/accountmanage/unlinkaccount', { account, ssoUser }, getToken());
}

export async function createAccountInvite({ account }) {
	let { inviteId } = await post('auth/accountmanage/createaccountinvite', {
		account
	}, getToken());
	return inviteId;
}
