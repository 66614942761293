import {
	search,
	getEntityclassByExternalId,
	countEntries
} from '@/repository/system.js';
import * as TdbRepo from '@/repository/tdb.js';
import { Request } from 'tdbcommon';

const state = {
	packages: [],
	processes: [],

	selectedType: null,
	selectedFilters: {},
	allAvailableOffers: [],

	pkgForClassifications: {},	// { [classid]: { id, rev } }

	asoSelected: null,
	insSelected: null,
	agrSelected: null,
	losSelected: null,
	noGeoPos: null,
	region: null,
	hints: {},
	agrData: null
};


const getters = {

	getPackageForClassification: (state) => (classificationId, count) => {
		if (count > 0 && state.pkgForClassifications.withOffers
			&& state.pkgForClassifications.withOffers[classificationId]) {
			return state.pkgForClassifications.withOffers[classificationId];
		}
		if (!state.pkgForClassifications.base) return null;
		return state.pkgForClassifications.base[classificationId];
	},

	canCreateRequest: (state, getters, rootState) => {
		return state.selectedType &&
			(state.insSelected || state.agrSelected) &&
			rootState.offer.search.modus === 'request' &&
			state.losSelected &&
			!state.noGeoPos;
	},

	requestMode: (state, getters, rootState) => {
		return rootState.offer.search.requestMode;
	},

	availableRequestTypes: (state, getters, rootState) => {
		let types = [];
		if (getters.getAsoId) {
			types.push(Request.RequestConstants.TYPE_ASO);
		}
		if (getters.getInsId) {
			types.push(Request.RequestConstants.TYPE_INS);
			types.push(Request.RequestConstants.TYPE_RESTRICTEDVET);
		}
		let animalkind = rootState.offer.search.filter.animalkind;
		let country = rootState.country.currentCountry;
		if (country.data.animalkindsUsingLowhatchRequests?.includes(animalkind) &&
			state.agrData?.staticData.eggBreeder
		) {
			types.push(Request.RequestConstants.TYPE_LOWHATCH);
		}
		console.log('availableTypes', types, state.agrData);
		if (state.agrData) {
			types = types.filter(e => state.agrData?.staticData.canRequest?.includes(e));
		}
		return types;
	},

	getAgrId: (state, getters, rootState, rootGetters) => {
		return rootGetters['auth/user/role'] === 'AGR' ? rootGetters['auth/token/account'] : state.agrSelected;
	},

	getInsId: (state, getters, rootState, rootGetters) => {	//CONTINUE HERE
		return rootGetters['auth/user/role'] === 'INS' ? rootGetters['auth/token/account'] : state.insSelected;
	},

	getAsoId: (state, getters, rootState, rootGetters) => {
		return rootGetters['auth/user/role'] === 'ASO' ? rootGetters['auth/token/account'] : state.asoSelected;
	},

	getRegion: (state) => {
		return state.region;
	},

	showHint: state => name => {
		if (name in state.hints) return state.hints[name];
		return false;
	},
};


const actions = {

	async loadPackages({ commit }) {
		let packageEc = await getEntityclassByExternalId('requestpackage');
		let res = await search(packageEc.id);
		commit('setPackages', res);
	},

	async init({ state, dispatch, rootGetters, commit }) {
		if (!state.packages.length) {
			await dispatch('loadPackages');
		}
		if (rootGetters['auth/user/role'] === 'AGR') {
			commit('setAgrData', rootGetters['auth/user/details']);
		}
	},

	async loadAgrData({ commit, getters, dispatch, rootGetters }) {	//CONTINUE HERE. not used
		if (rootGetters['auth/user/role'] === 'AGR' || !getters.getAgrId) return;
		let agrData = await dispatch('admin/loadEntity', { id: getters.getAgrId }, { root: true });
		commit('setAgrData', agrData.data);
	},

	async initRequest({ rootGetters, state, commit, getters }, { offergroup, pkg, sup }) {
		if (!pkg.rev) {
			let pkg2 = rootGetters['related/byId'](pkg.id);
			pkg.rev = pkg2.rev;
		}
		commit('offer/search/setFilterExport', true, { root: true });
		let env = {
			offergroup,
			country: rootGetters['country/currentCountry'].id,
			agr: getters.getAgrId,
			ins: getters.getInsId,
			agrSite: state.losSelected,
			package: { id: pkg.id, rev: pkg.rev },
			requestType: state.selectedType,
			searchFilter: rootGetters['offer/search/attributeFilter']
		};
		commit('offer/search/setFilterExport', false, { root: true });
		if (sup) env.sup = sup;
		if (getters.getAsoId) {
			env.aso = getters.getAsoId;
		}
		let res = await TdbRepo.requestInit(env);
		commit('reset');
		commit('offer/search/resetAllFilters', null, { root: true });
		return res;
	},
	async initFollowupRequest({ rootGetters, dispatch }, { process }) {
		let pkg = JSON.parse(JSON.stringify(process.followupRequest));
		if (!pkg.rev) {
			let tmp = rootGetters['related/byId'](pkg.id);
			if (!tmp) {
				tmp = await dispatch('admin/loadEntity', { id: pkg.id }, { root: true });
			}
			pkg.rev = tmp.rev;
		}
		if (!process.getId()) throw new Error('no process id found');
		let agr = process.getMembersWithRole().find(e => e.role === 'agr').id;
		let ins = process.getMembersWithRole().find(e => e.role === 'ins').id;
		let env = {
			followupFor: process.getId(),
			offergroup: process.offergroup.id,
			country: rootGetters['country/currentCountry'].id,
			agr,
			ins,
			agrSite: process.agrAddress.siteId,
			package: { id: pkg.id, rev: pkg.rev },
			requestType: process.type,
			searchFilter: process.searchFilter
		};
		let res = await TdbRepo.requestInit(env);
		return res;
	},

	async getFollowupProcesses(_, { ids }) {
		let processEc = await getEntityclassByExternalId('request-process');
		let attributeFilter = {
			$or: ids.map(id => {
				return {
					'field': '/data/isFollowupFor',
					'comparison': 'eq',
					'value': id
				};
			})
		};
		let result = await search(processEc.id, attributeFilter, { start: 0, limit: ids.length });
		return result;
	},

	async loadProcesses(_, { start, limit, sorting, attributeFilter }) {
		let processEc = await getEntityclassByExternalId('request-process');
		let count = await countEntries(processEc.id, attributeFilter);
		if (count === 0) {
			return { count, list: [] };
		}
		let list = await search(processEc.id, attributeFilter, { start, limit }, sorting);
		let wait = list.map(p => {
			return TdbRepo.loadPackage(p.data.package.id, p.data.package.rev);
		});
		let packages = await Promise.all(wait);
		// console.log('packages', packages);
		list = list.map(p => {
			let process = new Request.RequestProcess();
			process.fromStorage(p);
			let pkg = packages.find(pkg => pkg.getId() === process.package.id && pkg.getRev() === process.package.rev);
			if (!pkg) throw new Error(`cannot find pkg: ${process.package.id} / ${process.package.rev}`);
			process.setPackage(pkg);
			return process;
		});
		return { count, list };
	},

	async loadProcess(_, { id }) {
		await getEntityclassByExternalId('request-process');
		return TdbRepo.loadProcess(id);
	},

	async loadFieldForPackage(_, { pkg }) {
		let ret = await TdbRepo.loadFieldForPackage(pkg);
		return ret;
	},

	async fillProcessForm(_, { process, fieldData, attachments, owner, pkg }) {
		// returns process but missing some things like _meta, that are not available from TdbRepo.requestFill
		let entity = {};
		entity.data = await TdbRepo.requestFill({
			process, fieldData, attachments, owner
		});
		let requestProcess = new Request.RequestProcess();
		requestProcess.fromStorage(entity);
		requestProcess.setPackage(pkg);
		return requestProcess;
	},

	async retractProcess(_, { process }) {
		let entity = {};
		entity.data = await TdbRepo.retractProcess({ process: process.getId() });
		let requestProcess = new Request.RequestProcess();
		requestProcess.fromStorage(entity);
		return requestProcess;
	},

	async runClassifications({ state, getters, rootState, dispatch, rootGetters, commit }) {
		let rs = await dispatch('admin/getRulesetForKey', { key: 'request_classifications' }, { root: true });
		console.log('request_classifications', rs);
		if (!rs) return;
		const input = {
			env: {
				country: rootGetters['country/currentCountry'].id,
				animalkind: rootState.offer.search.filter.animalkind,
				userHasActiveAso: getters.availableRequestTypes.includes(Request.RequestConstants.TYPE_ASO),
				userHasActiveIns: getters.availableRequestTypes.includes(Request.RequestConstants.TYPE_INS)
			},
			input: {
				requestType: state.selectedType,
				animalusage: rootState.offer.search.filter.animalusage?.id
			},
			hints: {},
			request: {},
			requestWithOffers: {}
		};
		if (getters.getInsId) {
			input.env.userIns = getters.getInsId;
			try {
				let aut = await dispatch('getAutForAgr', { agr: getters.getAgrId, agrSite: state.losSelected });
				input.env.userAut = aut.id;
			} catch (e) {
				console.error('trying to find aut for ins: ', e);
			}
		}
		if (getters.getAsoId) {
			input.env.userAso = getters.getAsoId;
		}
		let facts = await dispatch('bbay/evaluate', {
			id: rs.id,
			rev: rs.rev,
			facts: input
		}, { root: true });
		commit('setRequestPackageForClassifications', {
			base: facts.request,
			withOffers: facts.requestWithOffers
		});
		commit('setShowHints', facts.hints);
	},

	async getAutForAgr({ dispatch }, { agr, agrSite }) {
		let agrData = await dispatch('admin/loadEntity', { id: agr }, { root: true });
		let allLos = agrData.data.userData.supData.flatMap(s => s.locationsOfSale);
		let los = allLos.find(e => e && e.id === agrSite);
		if (!los) throw new Error(`location of sale not found: ${agrSite}`);
		let region = los.address.region;	//@TODO change
		if (!region) throw new Error('no region specified');
		let { list } = await dispatch('admin/loadUsers', {
			start: 0,
			limit: 1,
			attributeFilter: {
				$and: [{
					field: '/data/registrationOptionName',
					comparison: 'eq',
					value: 'aut'
				}, {
					field: '/data/staticData/responsibleForRegions',
					comparison: 'eq',
					value: region
				}]
			}
		}, { root: true });
		if (!list.length) throw new Error(`no aut found for region ${region}`);
		let aut = list[0];
		return aut;
	}
};


const mutations = {

	reset(state) {
		state.asoSelected = null;
		state.insSelected = null;
		state.agrSelected = null;
		state.losSelected = null;
		state.selectedType = null;
		state.noGeoPos = null;
		state.region = null;
	},

	setPackages(state, list) {
		state.packages = list;
	},

	setSelectedAso(state, val) {
		state.asoSelected = val;
	},

	setSelectedIns(state, val) {
		state.insSelected = val;
	},

	setSelectedAgr(state, val) {
		state.agrSelected = val;
	},

	setSelectedLos(state, { site, region }) {
		state.losSelected = site;
		state.region = region;
	},

	setSelectedType(state, val) {
		state.selectedType = val;
	},

	setNoGeoPos(state, val) {
		state.noGeoPos = val;
	},

	setRequestPackageForClassifications(state, r) {
		state.pkgForClassifications = r;
	},

	setAgrData(state, data) {
		state.agrData = data;
	},

	setShowHints(state, hints) {
		state.hints = hints;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
