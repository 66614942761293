import { get, post } from '@/util/request.js';

export function getConfig(payload) {
	return get('export/config/get', payload);
}

export function createJob({ config, searchFilter, runParams, customColumnHeaders }) {
	return post('export/job/create', {
		config, runParams, searchFilter, customColumnHeaders
	});
}


export function getJob({ id }) {
	return post('export/job/get', { id });
}

export async function pollUntilJobComplete(jobId, wait, max) {
	let res;
	let success = await waitUntilTrue(async() => {
		res = await getJob({ id: jobId });
		return res.status === 'complete';
	}, wait, max);
	if (success) return res;
	else throw new Error('timeout');
}

function wait(ms, rej) {
	return new Promise(function(resolve, reject) {
		setTimeout(rej ? reject : resolve, ms);
	});
}

async function waitUntilTrue(fn, retry, max) {
	if (!max) max = 2000;
	if (!retry) retry = 100;
	let start = Date.now();
	do {
		let res = await fn();
		if (res) return true;
		await wait(retry);
		// console.log(Date.now() - start);
	} while (Date.now() - start < max);
	return false;
}
