import * as GeoRepo from '@/repository/geocode.js';

const actions = {

	async getLocationCity({ dispatch, rootState, rootGetters }, { countryId, citycode, cityname, usefuzzy = true }) {
		let countrycode;
		if (countryId && countryId !== rootState.country.currentCountry.id) {
			if (!rootState.country.list.length) await dispatch('country/loadAll', null, { root: true });
			let country = rootGetters['country/byId'](countryId);
			if (!country) throw new Error(`missing country '${countryId}'`);
			countrycode = country.data.code;
		} else {
			countrycode = rootState.country.currentCountry.data.code;
		}
		let list = await GeoRepo.getCityList({ countrycode, citycode, cityname, usefuzzy });
		return list;
	}
};

export default {
	namespaced: true,
	actions,
};
