import {
	search, getEntityclassByExternalId, getAttributesetByExternalId, getEntityTimeline,
	genericEntityCreate, genericEntityUpdate, getEntitiesById, countEntries, exists, getEntityByIdRev
} from '@/repository/system.js';
import { createAccount, updateAccount } from '@/repository/auth/accountmanagement.js';
import { setSettings, getSettings } from '@/repository/tdb.js';
import LRU from 'lru-cache';

const state = {
	entityclasses: {
		animalkind: null
	},
	attributesets: {
		animalkind: null
	},
	settings: null,
};

const getters = {
	listcache: () => type => {
		return listcache.get(type);
	}
};

const entitycache = new LRU({
	max: 500,
	maxAge: 1000 * 5
});
const listcache = new LRU({
	max: 500,
	maxAge: 1000 * 60
});
const timelineCache = new LRU({
	max: 500,
	maxAge: 1000 * 60
});
const agrControlNumberFreeCache = new LRU({
	max: 500,
	maxAge: 1000 * 60
});

const actions = {
	/* loads everything in an entityclass */
	loadList({ state, dispatch }, { type }) {
		let p = listcache.get(type);
		if (!p) {
			p = dispatch('loadEntityclass', type);
			p = p.then((ec) => search(ec));
			listcache.set(type, p);
			// console.log('listcache.set', type, listcache.length);
		} else {
			// console.log('listcache.got', type);
		}
		return p;
	},

	async loadEntityclass(_, name) {
		let ec = await getEntityclassByExternalId(name);
		return ec.id;
	},

	loadEntity(_, { id, rev }) {
		let key = `${id}#${rev || 'latest'}`;
		let e = entitycache.get(key);
		if (e) return e;
		let prom = (rev ? getEntityByIdRev(id, rev) : getEntitiesById([id]))
			.then(list => {
				let entity = 'data' in list ? list : list[0];
				if (!entity) throw new Error(`entity ${id} not found`);
				if (!rev) {
					entitycache.set(`${id}#${entity.rev}`, prom);
				}
				return entity;
			});
		entitycache.set(key, prom);
		// console.log('entitycache.set', id, entitycache.length);
		return prom;
	},

	clearCache() {
		entitycache.reset();
		listcache.reset();
	},

	loadEntities(_, { ids, throwIfNotAllFound }) {
		let notCached = [];
		let cached = [];
		ids.forEach(id => {
			let e = entitycache.get(id);
			if (e) cached.push(e);
			else notCached.push(id);
		});
		let all = cached.slice(0);
		if (notCached.length > 0) {
			let baseProm = getEntitiesById(notCached);
			notCached.forEach(id => {
				let eProm = baseProm.then(list => {
					let e = list.find(e => e.id === id);
					if (throwIfNotAllFound && !e) throw new Error(`entity with id ${id} not found`);
					return e;
				});
				all.push(eProm);
				entitycache.set(id, eProm);
			});
		}
		return Promise.all(all);
	},
	/* creates or updated an entity. entityclass and attributeset must have same externalId */
	async saveEntry({ state, commit, dispatch }, { entity, newData, type }) {
		let ec = await dispatch('loadEntityclass', type);
		if (!state.attributesets[type]) {
			let as = await getAttributesetByExternalId(type);
			commit('setAttributeset', { type, value: as.id });
		}
		let res;
		if (entity) {
			res = await genericEntityUpdate(entity, newData, state.attributesets[type]);
			let tl = timelineCache.get(entity.id);
			if (tl) {
				tl.push({ rev: res.rev, updated: res.updated });
			}
		} else {
			res = await genericEntityCreate(ec, state.attributesets[type], newData);
		}
		return res;
	},

	async loadOffergroups({ dispatch }, { start, limit, sorting, attributeFilter }) {
		const type = 'offergroup';
		let ec = await dispatch('loadEntityclass', type);
		let count = await countEntries(ec, attributeFilter);
		let list = await search(ec, attributeFilter, { start, limit }, sorting);
		return { count, list };
	},

	async loadUsers({ dispatch }, { start, limit, sorting, attributeFilter }) {
		const type = 'account';
		let ec = await dispatch('loadEntityclass', type);
		let count = await countEntries(ec, attributeFilter);
		let list = await search(ec, attributeFilter, { start, limit }, sorting);
		return { count, list };
	},

	async countEntries({ dispatch }, { attributeFilter, type }) {
		let ec = await dispatch('loadEntityclass', type);
		let count = await countEntries(ec, attributeFilter);
		return count;
	},

	createAccount(_, { registrationName, requiresConfirmation=false, userData, staticData }) {
		return createAccount({ registrationName, requiresConfirmation, userData, staticData });
	},

	updateAccount(_, { active, account, userData, staticData, maxUsers }) {
		return updateAccount({ active, account, userData, staticData, maxUsers });
	},

	async setSettings({ commit }, { data, persist }) {
		if (persist) await setSettings(data);
		commit('setSettings', data);
	},

	async getSettings({ commit, state }) {
		if (state.settings === null) {
			let settings = await getSettings();
			commit('setSettings', settings);
		}
		return state.settings;
	},

	async getRulesetForKey({ dispatch }, { key }) {
		let settings = await dispatch('getSettings');
		if (!settings.rulesets) return null;
		let rs = settings.rulesets.find(e => e.key === key);
		if (!rs) return null;
		return { id: rs.id, rev: rs.rev };
	},

	async loadEntityTimeline(_, { id }) {
		let res = timelineCache.get(id);
		if (!res) {
			res = await getEntityTimeline(id);
			timelineCache.set(id, res);
		}
		return res;
	},

	async checkAgrControlNumberIsFree(_, controlNumber) {
		let res = agrControlNumberFreeCache.get(controlNumber);
		if (typeof res !== 'boolean') {
			let ec = await getEntityclassByExternalId('account');
			let attributeFilter = { $and: [{
				field: '/data/staticData/role',
				comparison: 'eq',
				value: 'AGR'
			}, {
				field: '/data/userData/controlNumber',
				comparison: 'eq',
				value: controlNumber
			}] };
			res = await exists(ec.id, attributeFilter);
			res = !res;
			agrControlNumberFreeCache.set(controlNumber, res);
		}
		return res;
	}
};

const mutations = {

	setAttributeset(state, { type, value }) {
		state.attributesets[type] = value;
	},

	setSettings(state, settings) {
		state.settings = settings;
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
