import token from './auth/token.js';
import account from './auth/account.js';
import user from './auth/user.js';

export default {
	namespaced: true,
	modules: {
		token,
		account,
		user
	}
};
