<template>
	<div id="app">
		<LayoutMaintenance v-if="isMaintenance" />
		<LayoutDefaut v-else-if="isCountrySelected" />
		<LayoutCountrySelect v-else />
	</div>
</template>


<script>
import LayoutDefaut from '@/components/layout/default.vue';
import LayoutMaintenance from '@/components/layout/maintenance.vue';
import LayoutCountrySelect from '@/components/layout/country-selection.vue';
import { base64DecodeFile, downloadUpload } from '@/util/document-helper.js';

export default {
	name: 'App',
	components: {
		LayoutDefaut,
		LayoutCountrySelect,
		LayoutMaintenance
	},
	props: {

	},
	data() {
		return {

		};
	},
	computed: {
		isCountrySelected() {
			return this.$store.getters['country/isCountrySelected'];
		},
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		},
		isMaintenance() {
			return this.$store.state.base.maintenanceMode;
		},
		hash() {
			return this.$route.hash;
		}
	},
	watch: {
		hash() {
			this.checkDownload();
		}
	},
	created() {
		console.log('App', this);
	},
	mounted() {
		this.checkLogin();
		this.checkDownload(true);
	},
	methods: {
		async checkLogin() {
			if (this.$route.query.logmein === null) { // urlparam logmein exists (without value)
				// will not run twice if already triggered, needed here because we're awaiting it
				await this.$store.dispatch('auth/token/initApp');
				if (this.isLoggedIn) {
					let { query } = this.$route;
					delete query.logmein;
					let url = this.$router.resolve({ name: 'Home', query }).href;
					history.pushState({}, '', url);
				} else {
					this.gotoLogin();
				}
			}
		},
		async checkDownload(confirm = false) {
			let { hash } = this.$route;
			if (hash.substring(0, 10) === '#download=') {
				let idAndName = hash.substring(10);
				let newRoute = { ...this.$route };
				delete newRoute.hash;
				this.$router.replace(newRoute);
				let re = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\|\w+(?:.\w+)?$/u;
				if (re.test(idAndName)) {
					let name = idAndName.substring(37);
					let dlConfirm = true;
					if (confirm) {
						let message = this.$t('download.sure', [name]);
						let options = {
							okVariant: 'warning',
							okTitle: this.$t('default.common.yes'),
							cancelTitle: this.$t('default.common.no')
						};
						dlConfirm = await this.$bvModal.msgBoxConfirm(message, options);
					}
					if (dlConfirm) {
						let attachment = await this.$store.dispatch('upload/getAttachment', { id: idAndName.substring(0, 36) });
						let blob = base64DecodeFile(attachment);
						downloadUpload(blob, name);
					}
				}
			}
		},
		gotoLogin() {
			this.$store.dispatch('auth/token/authenticate');
		}
	}
};
</script>
