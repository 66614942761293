import EventBus from '@/util/eventbus.js';
import { BaseUrl, IsLive, LogUrl } from './env.js';
import { getToken } from './jwt-cache.js';
import i18n from '@/plugins/i18n.js';

/*
 * all requests to the server flow through here
 * this should only be called from a /repository/
 */

let backoffTimer = 0;
const backoffInterval = 500;

async function request(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries) {
	if (!data && !allowEmptyPayload) data = {};
	if (isNaN(retries)) retries = 0;
	let url = BaseUrl + path;
	let opts = {};
	opts.mode = 'cors';
	// opts.cache = 'no-cache';
	opts.headers = { 'Content-Type': 'application/json' };
	opts.redirect = 'follow';
	if (auth) {
		opts.headers.Authorization = `Bearer ${auth}`;
	}
	if (!post) {
		opts.method = 'GET';
		if (typeof payload === 'undefined') {
			url += `?__payload=${JSON.stringify(data)}`;
		}
	} else {
		opts.method = 'POST';
		opts.body = JSON.stringify(data);
	}
	EventBus.$emit('request.start', { url, opts, hideProgress });
	const res = await global.fetch(url, opts);
	if (res.status === 429) {	//too many requests
		if (retries > 5) {
			const error = new Error('failed after 5 retries');
			error.url = url;
			EventBus.$emit('error', error);
			EventBus.$emit('request.end', { url, opts, error });
			throw error;
		}
		return retry(path, data, post, auth, surpressError, allowEmptyPayload, true, retries+1);
	}
	try {
		if (res.status > 299) throw new Error(`http status ${res.status}`);
		let ct = res.headers.get('Content-Type');
		if (!ct.includes('application/json')) throw new Error(`invalid content-type was returned: ${ct}`);
		const json = await res.json();
		if (!json.ok) {
			let error = new Error();
			error.message = json.message ? json.message : 'unknown error';
			if (error.message.includes('Invalid authentication header')) {
				error.message = i18n.t('default.common.error.invalidAuth');
			}
			if (!json.message) console.error('unknown error', json);
			if (json.details) error.message += `\n - ${json.details}`;
			if (json.previous) error.previous = json.previous;
			if (json.stack) error.servicestack = json.stack;
			error.code = json.code;
			error.reqId = json.id;
			error.url = url;
			throw error;
		}
		EventBus.$emit('request.end', { url, opts, json });
		if (backoffTimer > 0) backoffTimer -= backoffInterval;
		return json.data;
	} catch (error) {
		if (!surpressError && !IsLive) {
			if (!error.url) error.url = url;
			EventBus.$emit('error', error);
		}
		EventBus.$emit('request.end', { url, opts, error });
		throw error;
	}
}

async function retry(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries) {
	backoffTimer += backoffInterval;
	await sleep(backoffTimer);
	return request(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries);
}

function sleep(ms) {
	return new Promise(function(resolve) {
		setTimeout(resolve, ms);
	});
}

export function get(path, data, auth, supe) {
	if (!auth) auth = getToken();
	return request(path, data, false, auth, supe);
}

export function post(path, data, auth, supe, emptyPayload, hideProgress) {
	if (!auth) auth = getToken();
	return request(path, data, true, auth, supe, emptyPayload, hideProgress);
}

export function log(data) {
	global.fetch(LogUrl, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json'
		},
		body: JSON.stringify(data)
	});
}
