import * as Common from 'tdbcommon';
import { i18n } from '@/plugins/i18n';

const state = {

	uiLocale: 'de_DE',
	fallbackLocale: 'de_DE',
	languages: Common.Config.Languages.List,
	userRoles: Common.Config.Users.Roles,
	userTimeFormat: 'H:mm:ss',
	userDateFormat: 'DD.MM.YY',
	CmsUsage: Common.Config.CmsUsage,
	maintenanceMode: false

};

const getters = {
	languages: () => {
		return state.languages;
	},
	CmsUsage: () => {
		return state.CmsUsage;
	}
};

const actions = {

};

const mutations = {

	setUiLocale(state, lang) {
		if (!state.languages.includes(lang)) throw new Error(`invalid lang: ${lang}`);
		state.uiLocale = lang;
		i18n.locale = lang;
		document.querySelector('html').setAttribute('lang', lang.substring(0, 2));
	},

	setIsMaintenance(state) {
		state.maintenanceMode = true;
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
