import { getEntityById, getEntityclassByExternalId, search } from '@/repository/system.js';

const state = {
	pages: [],
	ecId: null
};

const getters = {
	page: (state) => (id) => {
		let page = state.pages.find(e => e.id === id);
		if (!page) {
			return null;
		}
		return page;
	},
	usage: (state) => (usage) => {
		let page = state.pages.find(e => e.data.usage === usage);
		if (!page) {
			return null;
		}
		return page;
	}
};

const actions = {
	async loadPage({ commit, getters }, { id }) {
		if (getters.page(id)) return;
		let page = await getEntityById(id);
		commit('addPage', page);
	},
	async loadUsage({ commit, state, getters }, { usage }) {
		if (!state.ecId) {
			let ec = await getEntityclassByExternalId('cmspage');
			commit('setEcId', ec);
		}
		if (getters.usage(usage)) return;
		let filter = {
			$and: [{
				field: '/data/usage',
				comparison: 'eq',
				value: usage
			}]
		};
		let res = await search(state.ecId, filter);
		res.forEach(page => {
			commit('addPage', page);
		});
	}
};

const mutations = {
	addPage(state, page) {
		state.pages = state.pages.filter(p => p.id !== page.id);
		state.pages.push(page);
	},
	setEcId(state, { id }) {
		state.ecId = id;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
