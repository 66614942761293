import LRU from 'lru-cache';
import { post } from '@/util/request.js';

const cache = new LRU({
	max: 200,
	maxAge: 1000 * 60 * 60,
});

export async function getCity({ countrycode, citycode, cityname }) {
	let key = `${countrycode}_${citycode}_${cityname}`;
	if (cache.has(key)) {
		return cache.get(key);
	}
	let p;
	p = await post('geocode/get/city', {
		countrycode,
		citycode,
		cityname,
	});
	cache.set(key, p);
	return p;
}

export async function getCityList({ countrycode, citycode, cityname, usefuzzy }) {
	let key = `list_${countrycode}_${citycode}_${cityname}_${usefuzzy?'F':'0'}`;
	if (cache.has(key)) {
		return cache.get(key);
	}
	let p;
	let params = {
		countrycode,
		usefuzzy
	};
	if (citycode) params.citycode = citycode;
	if (cityname) params.cityname = cityname;
	if (!cityname && !citycode) throw new Error('must specify cityname or citycode');
	p = await post('geocode/list/city', params);
	cache.set(key, p);
	return p;
}
