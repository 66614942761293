import * as MailerRepo from '@/repository/mailer';

const state = {

	emailStatusCache: []

};

const getters = {
	getCachedVerficationStatus: state => email => {
		return state.emailStatusCache.find(e => e.email === email);
	},
	getSsoVerifiedEmail: (state, getters, rootState) => {
		return rootState.auth.token.ssoParsed?.email;
	}
};

const actions = {

	async getEmailVerificationStatus({ state, commit, getters, dispatch }, email) {
		let cached = state.emailStatusCache.find(e => e.email === email);
		if (cached) return cached.status;
		if (email === getters.getSsoVerifiedEmail) {
			await dispatch('verifyViaSsoEmail');
			let { status } = getters.getCachedVerficationStatus(email);
			return status;
		}
		let { status, expired } = await MailerRepo.getEmailVerificationStatus(email);
		commit('setVerificationStatus', { email, status, expired });
		return status;
	},

	async requestVerification({ commit }, { email }) {
		let success = await MailerRepo.requestVerification({ email });
		if (success) commit('setVerificationStatus', { email, status: 'pending' });
	},

	async completeVerification({ commit }, { email, code }) {
		let success = await MailerRepo.completeVerification({ email, code, accepted: true });
		if (success) commit('setVerificationStatus', { email, status: 'verified' });
	},

	async verifyViaSsoEmail({ getters, rootState, commit }) {
		let email = getters.getSsoVerifiedEmail;
		if (!email || getters.getCachedVerficationStatus(email)?.status === 'verified') return;
		let status = await MailerRepo.ssoVerification({ token: rootState.auth.token.ssoToken });
		commit('setVerificationStatus', { email, status });
	},

	async checkForUnverifiedMails({ dispatch, rootState }) {
		const accountDetails = rootState.auth.user.details;
		let emails = new Set();
		if (accountDetails.userData.supData) {
			accountDetails.userData.supData.forEach(sd => {
				sd.locationsOfSale.forEach(los => {
					if (los.address && los.address.email) {
						emails.add(los.address.email);
					}
				});
			});
		}
		if (accountDetails.userData.address && accountDetails.userData.address.email) {
			accountDetails.userData.address.email.split(' ').forEach(e => {
				emails.add(e);
			});
		}
		for (let e of Array.from(emails)) {
			let status = await dispatch('getEmailVerificationStatus', e);
			if (status !== 'verified') return true;
		}
		return false;
	}

};

const mutations = {

	setVerificationStatus(state, { email, status, expired }) {
		let entry = state.emailStatusCache.find(e => e.email === email);
		if (entry) {
			entry.status = status;
			entry.expired = !!expired;
		} else {
			state.emailStatusCache.push({ email, status, expired });
		}
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
