<template>
	<div id="main-nav">
		<b-navbar toggleable="sm" type="dark">
			<b-navbar-brand :to="{ name: 'Home' }">
				<b-img v-if="$t('header.logo') === 'organicXlivestock'"
					src="@/assets/logo-w.png"
					height="34"
					:alt="$t('altText.home.logo')"
				/>
				<b-nav-text v-else>
					{{ $t('header.logo') }}
				</b-nav-text>
			</b-navbar-brand>

			<b-nav-text>
				{{ $t('header.title') }} - {{ countryLabels | toLocale }} - {{ $t('common.role.'+role) }}
			</b-nav-text>
			<b-navbar-nav class="ml-auto">
				<lang-select />
				<main-menu />
			</b-navbar-nav>
		</b-navbar>
		<NavAnimalkind />
	</div>
</template>

<script>
import NavAnimalkind from '@/components/layout/nav-animalkind.vue';
import MainMenu from './main-menu.vue';
import LangSelect from './lang-select.vue';
/*
 * builds main menu from config
 * will be expanded to filter by user/role
 */
export default {
	name: 'MainNav',
	components: {
		MainMenu,
		LangSelect,
		NavAnimalkind,
	},
	data() {
		return {
		};
	},
	computed: {
		role() {
			return this.$store.getters['auth/user/role'];
		},
		countryLabels() {
			return this.$store.getters['country/countryLabels'];
		},
		country() {
			return this.$store.getters['country/currentCountry'];
		}
	},
	methods: {

	}
};
</script>
