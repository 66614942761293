<template>
	<div class="footer-wrapper">
		<b-container>
			<PageContent usage="footer" />
		</b-container>
	</div>
</template>

<script>
import PageContent from '@/components/layout/page-content.vue';

export default {
	name: 'MainFooter',
	components: {
		PageContent
	},
	props: {

	},
	data() {
		return {

		};
	},
	computed: {

	},
	methods: {

	},
};
</script>
