import { createAccountInvite, unregisterAccount } from '@/repository/auth/accountmanagement.js';

const state = {

};

const getters = {
};

const actions = {
	createInvite(_, { accountId }) {
		return createAccountInvite({ account: accountId });
	},
	unregisterAccount(_, { account, ssoUser }) {
		return unregisterAccount(account, ssoUser);
	}
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
