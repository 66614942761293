import LRU from 'lru-cache';
import { search } from './system.js';
import { post } from '@/util/request.js';

const cache = new LRU({
	max: 50,
	maxAge: 1000 * 60 * 10
});

export function cachedSearch(ec, filter) {
	let key = `${ec}_${JSON.stringify(filter)}`;
	if (!cache.has(key)) {
		let p = search(ec, filter, { start: 0, limit: 1000 });
		cache.set(key, p);
		return p;
	} else {
		return cache.get(key);
	}
}

export function runAggregateQuery(ec, filter, aggregate) {
	return post('search/aggregate/run', {
		entityClass: ec,
		attributeFilter: filter,
		aggregate
	});
}
