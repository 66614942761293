import { AdminParty } from './env.js';
/*
 * extra cache for setting the current JWT
 * needed because the request.js cannot require the store
 * without creating a recursive dependency
 */
let currentToken = null;

export function getToken() {
	if (AdminParty) return null;
	// console.log('getToken', currentToken && currentToken.length > 0);
	return currentToken;
}

export function setToken(t) {
	// console.log('setToken', !!t);
	currentToken = t;
}
