import { get, post } from '@/util/request.js';
import { createPatch } from 'rfc6902';
import LRU from 'lru-cache';

const asCache = new Map();
let ecListPromise;

function getEntityclassList() {
	return get('eav/entityclass/list');
}

export async function getEntityclassByExternalId(extId) {
	if (!ecListPromise) {
		ecListPromise = getEntityclassList();
	}
	let list = await ecListPromise;
	let ec = list.find(e => e.externalId === extId);
	if (!ec) throw new Error(`ec not found: ${extId}`);
	return ec;
}

export async function getAttributesetByExternalId(extId) {
	if (!asCache.has(extId)) {
		let ec = await get('eav/attribute/getbyexternalid', extId);
		asCache.set(extId, ec);
	}
	return asCache.get(extId);
}

export function genericEntityUpdate(oldEntity, newData, changeset, surpressError) {
	if (!oldEntity) {
		return null;
	}
	const patch = createPatch(oldEntity.data, newData);
	if (patch.length === 0) {
		return oldEntity;
	}
	let payload = {
		id: oldEntity.id,
		rev: oldEntity.rev,
		patch
	};
	if (changeset && changeset !== oldEntity.attributeset) {
		payload.changeset = changeset;
	}
	return post('eav/entity/updatefrompatch', payload, null, surpressError);
}

export function genericEntityCreate(entityclass, attributeset, data) {
	return post('eav/entity/create', {
		entityclass,
		attributeset,
		data
	});
}

export function genericEntityDelete(id) {
	return post('eav/entity/delete', { id });
}

export function search(entityclass, filter, pagination, sorting) {
	return _search(entityclass, filter, 'search/search/execute', pagination, sorting);
}

export const searchWindow = 200;

export async function _search(entityclass, filter, endpoint, pagination, sorting) {
	let result = [];
	let searchRequest = {
		pagination: {
			start: 0,
			limit: searchWindow
		}
	};
	if (entityclass) searchRequest.entityClass = entityclass;
	if (filter) searchRequest.attributeFilter = filter;
	if (pagination) searchRequest.pagination = pagination;
	if (sorting) searchRequest.sorting = sorting;

	let temp;
	do {
		temp = await post(endpoint, searchRequest);
		result = result.concat(temp);
		if (!pagination) {
			searchRequest.pagination.start += searchWindow;
		}
	} while (!pagination && temp && temp.length >= searchWindow);

	return result;
}

export async function countEntries(entityClass, filter) {
	const endpoint = 'search/search/count';
	let searchRequest = {
		entityClass
	};
	if (filter) searchRequest.attributeFilter = filter;
	let result = await post(endpoint, searchRequest);
	return result;
}

export async function exists(entityClass, attributeFilter) {
	let searchRequest = {
		entityClass,
		attributeFilter,
		pagination: {
			start: 0,
			limit: 1
		}
	};
	let result = await post('search/search/execute', searchRequest);
	return !!result.length;
}

const entitycache = new LRU({
	max: 500,
	maxAge: 1000 * 60 * 60
});

export function getEntityByIdRev(id, rev) {
	let key = `${id}_${rev}`;
	let p = entitycache.get(key);
	if (!p) {
		p = get(`eav/entity/link/${id}/${rev}`, {});
		entitycache.set(key, p);
	}
	return p;
}
export function getEntityByIdDate(id, date) {
	let key = `${id}_${date}`;
	let p = entitycache.get(key);
	if (!p) {
		p = get(`eav/entity/get`, { id, date });
		entitycache.set(key, p);
	}
	return p;
}
export async function getEntityById(id) {
	let list = await getEntitiesById([id]);
	if (list.length === 0) throw new Error(`entity not found: ${id}`); // never possible?! so no translation
	return list[0];
}
export function getEntitiesById(ids) {
	return post('search/search/ids', { ids });
}

export function getDomain() {
	return get('eav/domain/getbyurl');
}

export function getEntityTimeline(id) {
	return get('eav/entity/gettimeline', id);
}
