import {
	getEntityclassByExternalId,
	getAttributesetByExternalId,
	genericEntityUpdate,
	genericEntityCreate,
	countEntries,
	search
} from '@/repository/system.js';
import { get, post } from '@/util/request.js';

const state = {
	entityclass: null,
	attributeset: null,
	offerEntityclass: null,
	offerAttributeset: null
};

const getters = {
};

const actions = {

	async loadEntity(_, { id }) {
		let tmp = await post('search/search/ids', { ids: [id] });
		return tmp[0];
	},
	loadEntities(_, { ids }) {
		return post('search/search/ids', { ids });
	},

	/* creates or updated an entity. entityclass and attributeset must have same externalId */
	async saveEntry({ state, commit }, { entity, newData }) {
		if (!state.entityclass) {
			let ec = await getEntityclassByExternalId('upload');
			commit('setEntityclass', { value: ec.id });
		}
		if (!state.attributeset) {
			let as = await getAttributesetByExternalId('upload');
			commit('setAttributeset', { value: as.id });
		}
		let res;
		if (entity) {
			res = await genericEntityUpdate(entity, newData, state.attributeset);
		} else {
			res = await genericEntityCreate(state.entityclass, state.attributeset, newData);
		}
		return res;
	},

	async createAttachment({ dispatch }, { newData, attachment }) {
		let entity = await dispatch('saveEntry', { newData });
		return post('eav/entity/setattachment', { rev: entity.rev, id: entity.id, attachment });
	},

	setAttachment(_, { rev, id, attachment }) {
		return post('eav/entity/setattachment', { rev, id, attachment });
	},

	getAttachment(_, { id }) {
		return get('eav/entity/getattachment', { id });
	},

	async loadList({ commit, state }, { start, limit, sorting, attributeFilter }) {
		if (!state.entityclass) {
			let ec = await getEntityclassByExternalId('upload');
			commit('setEntityclass', { value: ec.id });
		}
		let count = await countEntries(state.entityclass, attributeFilter);
		let list = await search(state.entityclass, attributeFilter, { start, limit }, sorting);
		return { count, list };
	},

	async deleteEntity({ dispatch }, { id }) {
		let ret = await post('eav/entity/delete', { id });
		let offers = [];
		let errors = [];
		try {
			offers = await dispatch('_searchOffersWithUpload', { id });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`attachment deleted, but could not search for offers to remove attachment '${id}'.`, error);
			throw new Error(`Attachment deleted, but could not search for offers to remove attachment '${id}': ${error}`);
		}
		for (let offer of offers) {
			try {
				// eslint-disable-next-line no-await-in-loop
				await dispatch('_removeAttachmentFromOffer', { id, offer });
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(`attachment deleted, but could not remove it '${id}' from offer '${offer.id}'.`, err);
				errors.push({ offerId: offer.id, error: `${err.name}: ${err.message}` });
			}
		}
		if (errors.length) {
			throw new Error(`Attachment '${id}' deleted, but could not remove it from offer: ${JSON.stringify(errors)}`);
		}
		return ret;
	},

	deleteLogo(_, { id }) {
		return post('eav/entity/delete', { id });
	},

	async _searchOffersWithUpload({ commit, state }, { id }) {
		if (!state.offerEntityclass) {
			let ec = await getEntityclassByExternalId('offer');
			commit('setOfferEntityclass', { value: ec.id });
		}
		let attributeFilter = {
			field: '/data/attachment',
			comparison: 'eq',
			value: id
		};
		return search(state.offerEntityclass, attributeFilter);
	},
	async _removeAttachmentFromOffer({ commit, state }, { id, offer }) {
		let newData = JSON.parse(JSON.stringify(offer.data));
		if (newData.attachment.length > 1) newData.attachment = offer.data.attachment.filter(e => e !== id);
		else delete newData.attachment;
		if (!state.offerAttributeset) {
			let as = await getAttributesetByExternalId('offer');
			commit('setOfferAttributeset', { value: as.id });
		}
		let res = await genericEntityUpdate(offer, newData, state.offerAttributeset);
		return res;
	}
};

const mutations = {

	setEntityclass(state, { value }) {
		state.entityclass = value;
	},
	setAttributeset(state, { value }) {
		state.attributeset = value;
	},
	setOfferEntityclass(state, { value }) {
		state.offerEntityclass = value;
	},
	setOfferAttributeset(state, { value }) {
		state.offerAttributeset = value;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
