import formfield from './request/formfield.js';
import main from './request/main.js';
import view from './request/view.js';

export default {
	namespaced: true,
	modules: {
		formfield,
		main,
		view
	}
};
