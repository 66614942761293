import { search, getEntityclassByExternalId, getEntityByIdDate } from '@/repository/system.js';
import { cachedSearch, runAggregateQuery } from '@/repository/search.js';
import { inputTypeMap } from '@/util/form/offer-field.js';
import EventBus from '@/util/eventbus.js';
import moment from 'moment';
import { toLocale } from '@/plugins/filters.js';
import Vue from 'vue';

const state = {
	modus: 'default',
	spotMarketSwitch: false,
	requestMode: false,
	filterExport: false,
	animalmenus: [],
	offergroupOptions: [],
	animalusageOptions: [],
	animalusagePool: [],
	animalkindOptions: null,
	ageCategoryOptions: null,
	category1Options: [],
	category2Options: null,
	asoOptions: [],
	requestOptionsFilter: null,
	dynamicFields: [],
	defaultLocationGeoRadius: 200,
	geoRadiusByRegion: {},
	filter: {
		animalkind: null,
		animalusage: [],
		location: null,
		locationGeoRadius: 200,
		offergroup: null,
		offerspec: null,
		gender: null,
		ageCategory: null,
		category1: [],
		amount: null,
		amountComparison: null,
		aso: [],
		statusWeekly: null,
		showUnavailable: false,
		category2: null,
		history: null,
		historyTill: null,
		dynamic: []
	},
	filterVisible: {},
	filterMandatory: {},
	offerListGrouped: [],
	offerGroupsOpen: [],
	calendarSettings: {},
	loaded: false,
	amountComparisonOptions: [{
		key: 'gte',
		label: '>',
		labelLong: '>',
	}, {
		key: 'lte',
		label: '<',
		labelLong: '<',
	}, {
		key: 'eq',
		label: '=',
		labelLong: '=',
	}],
	displayHints: {},
	allowTransnationalSearch: false
};

const getters = {

	animalmenus: (state, _, rootState) => {
		// todo: do not load every animalmenu, but only the ones from currentcountry; check if countryswitch still works with it tho
		return state.animalmenus.filter(e => e.data.countries.includes(rootState.country.currentCountry.id));
	},

	animalkindOptions: (state) => {
		return state.animalkindOptions || [];
	},

	animalusageOptions: (state) => {
		return state.animalusageOptions || [];
	},

	offergroupOptions: (state) => {
		return state.offergroupOptions || [];
	},

	offergroupSpecOptions: (state, getters, rootState, rootGetters) => {
		if (!state.filter.offergroup) return [];
		let countryId = rootGetters['country/currentCountry'].id;
		return state.filter.offergroup.data.offerSpecifications.filter(e => e.activeInCountries.includes(countryId)) || [];
	},

	ageCategoryOptions: (state) => {
		return state.ageCategoryOptions || [];
	},

	category1Options: (state) => {
		return state.category1Options || [];
	},

	category2Options: (state) => {
		return state.category2Options || [];
	},

	asoOptions: (state) => {
		return state.asoOptions || [];
	},

	offerGroupKeys: (state) => {
		if (!state.loaded) return [];
		let base = new Set(Object.keys(state.offerListGrouped));
		if (state.requestMode) {
			if (state.filter.offergroup) {
				base.add(state.filter.offergroup.id);
			} else {
				state.offergroupOptions.forEach(e => {
					base.add(e.id);
				});
			}
		}
		return Array.from(base.values());
	},

	isOfferGroupOpen: (state) => (groupId) => {
		return state.offerGroupsOpen.includes(groupId);
	},

	offersFromOpenGroups: (state, getters) => {
		return Object.entries(state.offerListGrouped)
			.filter((e) => getters.isOfferGroupOpen(e[0]))
			.map(e => e[1].offers)
			.flat();
	},

	usesPoultrySystem: (state, _, rootState) => {
		if (!rootState.country || !state.filter.animalkind) return null;
		let { animalkindsUsingPoultrySystem } = rootState.country.currentCountry.data;
		return animalkindsUsingPoultrySystem.includes(state.filter.animalkind);
	},

	allowTransnationalSearch: (state) => {
		return state.allowTransnationalSearch;
	},

	startOfIsoWeek() {
		return moment().startOf('isoWeek').format('YYYY-MM-DD');
	},

	dynamicFieldValue: (state) => (fieldId) => {
		let entry = state.filter.dynamic.find(e => e.id === fieldId);
		return entry ? entry.value : null;
	},

	isFilterVisible: (state) => (fieldId) => {
		if (fieldId in state.filterVisible) return state.filterVisible[fieldId];
		if (state.filterVisible.dynamic && fieldId in state.filterVisible.dynamic) return state.filterVisible.dynamic[fieldId];
		return false;
	},

	isFilterMandatory: (state) => (fieldId) => {
		if (fieldId in state.filterMandatory) return state.filterMandatory[fieldId];
		if (state.filterMandatory.dynamic && fieldId in state.filterMandatory.dynamic) return state.filterMandatory.dynamic[fieldId];
		return false;
	},

	getCalendarMin: state => {
		let cat2 = state.filter.category2;
		if (cat2 && cat2.id in state.calendarSettings && 'min' in state.calendarSettings[cat2.id]) {
			return state.calendarSettings[cat2.id].min;
		}
		let min;
		let check = (cat1) => {
			if (!(cat1.id in state.calendarSettings) || !('min' in state.calendarSettings[cat1.id])) return;
			let num = state.calendarSettings[cat1.id].min;
			if (isNaN(min)) {
				min = num;
			} else {
				min = Math.min(min, num);
			}
		};
		if (Array.isArray(state.filter.category1)) {
			state.filter.category1.forEach(check);
		} else if (state.filter.category1) {
			check(state.filter.category1);
		}
		return min;
	},

	getCalendarMax: state => {
		let cat2 = state.filter.category2;
		if (cat2 && cat2.id in state.calendarSettings && 'max' in state.calendarSettings[cat2.id]) {
			return state.calendarSettings[cat2.id].max;
		}
		let max;
		let check = (cat1) => {
			if (!(cat1.id in state.calendarSettings) || !('max' in state.calendarSettings[cat1.id])) return;
			let num = state.calendarSettings[cat1.id].max;
			if (isNaN(max)) {
				max = num;
			} else {
				max = Math.max(max, num);
			}
		};
		if (Array.isArray(state.filter.category1)) {
			state.filter.category1.forEach(check);
		} else if (state.filter.category1) {
			check(state.filter.category1);
		}
		return max;
	},

	allMandatoryFiltersSet: (state, getters) => {
		for (let key of Object.keys(state.filterMandatory)) {
			if (getters.isFilterMandatory(key)) {
				if (key === 'dynamic') {
					for (let fieldId of Object.keys(state.filterMandatory.dynamic)) {
						let value = getters.dynamicFieldValue(fieldId);
						if (!value || Array.isArray(value) && value.length === 0) return false;
					}
				} else {
					if (key === 'calendarweek') key = 'statusWeekly';
					else if (key === 'agegroup') key = 'ageCategory';
					let value = state.filter[key];
					if (!value || Array.isArray(value) && value.length === 0) return false;
				}
			}
		}
		return true;
	},

	geoRadius: (state, getters, rootState, rootGetters) => {
		if (state.modus !== 'request') return state.filter.locationGeoRadius;
		let region = rootGetters['request/main/getRegion'];
		return region && region in state.geoRadiusByRegion ? state.geoRadiusByRegion[region].requestGeoRadius : state.defaultLocationGeoRadius;
	},

	attributeFilter: (state, getters, rootState) => {
		if (!state.filter.animalkind) throw new Error('animalkind not set');
		let $and = [];
		if (!getters.allowTransnationalSearch || getters.allowTransnationalSearch && !state.filter.location) {
			$and.push({
				field: '/data/country',
				comparison: 'eq',
				value: rootState.country.currentCountry.id
			});
		}
		if (state.modus === 'poultry-spot') {
			$and.push({
				field: '/data/spotMarket',
				comparison: 'eq',
				value: true
			});
		} else {
			$and.push({
				$or: [{
					field: '/data/spotMarket',
					comparison: 'eq',
					value: false,
				}, {
					$not: {
						field: '/data',
						comparison: 'exists',
						value: 'spotMarket',
					},
				}],
			});
		}
		$and.push({
			field: '/data/animalkind',
			comparison: 'eq',
			value: state.filter.animalkind
		});
		if (state.filter.animalusage) {
			if (Array.isArray(state.filter.animalusage)) {
				let $or = state.filter.animalusage.map(e => {
					return {
						field: '/data/animalusage',
						comparison: 'eq',
						value: e.id
					};
				});
				$and.push({ $or });
			} else {
				$and.push({
					field: '/data/animalusage',
					comparison: 'eq',
					value: state.filter.animalusage.id
				});
			}
		}
		if (state.filter.location && state.filter.location.geoPosition && getters.geoRadius) {
			$and.push({
				field: '/data/geoPosition',
				comparison: 'geoRadius',
				value: {
					distance: `${getters.geoRadius}km`,
					location: state.filter.location.geoPosition,
				},
			});
		}
		if (state.filter.offergroup) {
			$and.push({
				field: '/data/offergroup',
				comparison: 'eq',
				value: state.filter.offergroup.id
			});
		}
		if (state.filter.offerspec) {
			$and.push({
				field: '/data/offerspecification',
				comparison: 'eq',
				value: state.filter.offerspec.id,
			});
		}
		if (state.filter.gender) {
			$and.push({
				field: '/data/gender',
				comparison: 'eq',
				value: state.filter.gender
			});
		}
		if (state.filter.ageCategory) {
			$and.push({
				field: '/data/indexData/ageCategory',
				comparison: 'eq',
				value: state.filter.ageCategory.id,
			});
		}
		if (state.filter.category1 && (!Array.isArray(state.filter.category1) || state.filter.category1.length > 0)) {
			let val = Array.isArray(state.filter.category1) ? state.filter.category1 : [state.filter.category1];
			let $or = val.map(e => {
				return {
					field: '/data/indexData/category1',
					comparison: 'eq',
					value: e.id,
				};
			});
			if ($or.length) $and.push({ $or });
		}
		if (state.filter.amount && state.filter.amount > 0 && state.filter.amountComparison && (!state.requestMode || state.filterExport)) {
			$and.push({
				field: '/data/amount',
				comparison: state.filter.amountComparison.key,
				value: parseInt(state.filter.amount),
			});
		}
		if (state.filter.history) {
			if (state.filter.historyTill) {
				$and.push({
					$nested: {
						path: '/data/history',
						match: {
							$or: [{
								$and: [{
									field: '/data/history/begin',
									comparison: 'lte',
									value: state.filter.historyTill,
								}, {
									field: '/data/history/end',
									comparison: 'gte',
									value: state.filter.history,
								}],
							}, {
								$and: [{
									field: '/data/history/begin',
									comparison: 'lte',
									value: state.filter.historyTill,
								}, {
									$not: {
										field: '/data/history',
										comparison: 'exists',
										value: 'end',
									},
								}],
							}],
						},
					},
				});
			} else {
				$and.push({
					$nested: {
						path: '/data/history',
						match: {
							$or: [{
								$and: [{
									field: '/data/history/begin',
									comparison: 'lte',
									value: state.filter.history,
								}, {
									field: '/data/history/end',
									comparison: 'gte',
									value: state.filter.history,
								}],
							}, {
								$and: [{
									field: '/data/history/begin',
									comparison: 'lte',
									value: state.filter.history,
								}, {
									$not: {
										field: '/data/history',
										comparison: 'exists',
										value: 'end',
									},
								}],
							}],
						},
					},
				});
			}
		} else if (state.filter.statusWeekly) {
			if (getters.isFilterVisible('calendarweek')) {
				const $or = [];
				let add = (wk) => {
					$or.push({
						field: '/data/statusWeekly/week',
						comparison: 'eq',
						value: wk,
					});
				};
				add(state.filter.statusWeekly);	//the chosen week must always be first for request-init
				if (state.calendarSettings.weeksBefore) {
					let base = new Date(state.filter.statusWeekly);
					for (let i=1; i<=state.calendarSettings.weeksBefore; i++) {
						let d = new Date(base.getTime());
						d.setDate(d.getDate()-(i*7));
						add(d.toJSON().substr(0, 10));
					}
				}
				if (state.calendarSettings.weeksAfter) {
					let base = new Date(state.filter.statusWeekly);
					for (let i=1; i<=state.calendarSettings.weeksAfter; i++) {
						let d = new Date(base.getTime());
						d.setDate(d.getDate()+(i*7));
						add(d.toJSON().substr(0, 10));
					}
				}
				$and.push({
					$or: [
						{
							$nested: {
								path: '/data/statusWeekly',
								match: {
									$or: [
										{
											$and: [
												{ $or },	//request-init is dependent on this structure
												{
													field: '/data/statusWeekly/available',
													comparison: 'eq',
													value: true,
												}
											]
										}
									]
								},
							}
						},
						{
							$not: {
								$nested: {
									path: '/data/statusWeekly',
									match: { $or },
								}
							}
						}
					]
				});
			}
			$and.push({
				$not: {
					field: '/data/status',
					comparison: 'eq',
					value: 'archived',
				},
			});
			$and.push({
				field: '/data/ownerActive',
				comparison: 'eq',
				value: true
			});
		} else if (state.filter.showUnavailable) {
			$and.push({
				$or: [{
					field: '/data/status',
					comparison: 'eq',
					value: 'available',
				}, {
					field: '/data/status',
					comparison: 'eq',
					value: 'unavailable',
				}, {
					field: '/data/ownerActive',
					comparison: 'eq',
					value: true
				}],
			});
		} else {
			$and.push({
				$or: [{
					field: '/data/status',
					comparison: 'eq',
					value: 'available'
				}, {
					$and: [{
						field: '/data/status',
						comparison: 'eq',
						value: 'unavailable'
					}, {
						field: '/data',
						comparison: 'exists',
						value: 'availableFrom'
					}],
				}],
			});
			$and.push({
				field: '/data/ownerActive',
				comparison: 'eq',
				value: true
			});
		}
		if (state.filter.category2) {
			$and.push({
				field: '/data/category2',
				comparison: 'eq',
				value: state.filter.category2.id,
			});
		}
		if (state.filter.aso.length) {
			let $or = state.filter.aso.map(e => {
				return {
					field: '/data/associations',
					comparison: 'eq',
					value: e.id,
				};
			});
			$and.push({ $or });
		}
		let dynamic = state.filter.dynamic.map(({ id, value }) => {
			let field = state.dynamicFields.find(e => e.id === id);
			if (!field) throw new Error(`unknown field: ${id}`);
			let inputType = inputTypeMap[field.data.type];
			let $or = [];
			let parsed = typeof value === 'object' && value.id ? value.id : value;
			let fieldComparison = {
				field: '/data/dynamicFields/field',
				comparison: 'eq',
				value: id
			};
			if (Array.isArray(value)) {
				if (value.length === 0) return null;
				value.forEach(element => {
					parsed = typeof element === 'object' && element.id ? element.id : element;
					$or.push({ $and: [
						fieldComparison, {
							field: `/data/dynamicFields/${inputType}`,
							comparison: 'eq',
							value: parsed
						}
					] });
				});
			} else {
				$or.push({ $and: [
					fieldComparison, {
						field: `/data/dynamicFields/${inputType}`,
						comparison: 'eq',
						value: parsed
					}
				] });
			}
			return {
				$nested: {
					path: '/data/dynamicFields',
					match: {
						$or
					}
				}
			};
		}).filter(e => !!e);
		$and = $and.concat(dynamic);
		return { $and };
	},

	amountComparisonOptions: (state, getters) => {
		if (state.modus === 'request' && getters.usesPoultrySystem) {
			return state.amountComparisonOptions.slice(0, 1);
		}
		if (state.modus === 'request' && !getters.usesPoultrySystem) {
			return state.amountComparisonOptions.slice(2, 3);
		}
		return state.amountComparisonOptions.slice(0, 2);
	}


};

const actions = {

	async loadOfferSummary({ commit, getters, rootGetters, state }) {
		if (!state.filter.animalkind) return;
		let { requestMode } = state;
		let ec = await getEntityclassByExternalId('offer');
		let query = {
			field: '/data/offergroup',
			type: 'term',
			key: 'groups',
			sub: [{
				field: '/data/amount',
				type: 'sum',
				key: 'amount'
			}, {
				field: '/data/owner',
				type: 'term',
				key: 'owners'
			}]
		};
		let attributeFilter = filterAutApproval(getters.attributeFilter, rootGetters);
		let res = await runAggregateQuery(ec.id, attributeFilter, query);
		let grouped = {};
		if (res.aggregations) res.aggregations.groups.buckets.forEach(group => {
			grouped[group.key] = {
				id: group.key,
				count: group.doc_count,
				sups: group.owners.buckets.length,
				amount: group.amount.value,
				offers: []
			};
		});
		if (requestMode === state.requestMode) {
			commit('setGroupedResult', grouped);
			EventBus.$emit('offer-search-reset');
			window.scrollTo(0, 0);
		}
	},

	async loadOffers({ commit, getters, state, rootGetters }, { groupId }) {
		if (!state.filter.animalkind) return;
		let after = state.offerListGrouped[groupId].offers.length;
		if (after >= state.offerListGrouped[groupId].count) return;
		let attributeFilter = filterAutApproval(getters.attributeFilter, rootGetters);
		if (!state.filter.offergroup) {
			attributeFilter.$and.push({
				field: '/data/offergroup',
				comparison: 'eq',
				value: groupId
			});
		}
		let ec = await getEntityclassByExternalId('offer');
		let offers = await search(ec.id, attributeFilter, { start: after, limit: 50 });
		commit('addLoadedOffers', { groupId, after, offers });
	},

	async setOffergroupOptions({ commit, state, rootState }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let country = rootState.country.currentCountry.id;
		let animalkind = state.filter.animalkind;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/activeInCountries',
			comparison: 'eq',
			value: country
		});
		attributeFilter.$and.push({
			field: '/data/animalkind',
			comparison: 'eq',
			value: animalkind
		});
		let ec = await getEntityclassByExternalId('offergroup');
		//will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		list = list.sort((a, b) => {
			let localeA = toLocale(a.data.labels).toLowerCase();
			let localeB = toLocale(b.data.labels).toLowerCase();
			if (localeA < localeB) return -1;
			else if (localeA > localeB) return 1;
			return 0; // equal
		});
		commit('setOptions', { name: 'offergroup', list });
		//update current filter
		let currentFilter = state.filter.offergroup;
		if (currentFilter && !list.find(e => e.id === currentFilter.id)) {
			commit('setFilter', { name: 'offergroup', filter: null });
			if (state.filter.offerspec) {
				commit('setFilter', { name: 'offerspec', filter: null });
			}
		}
	},

	checkOfferSpecifications({ commit, state, getters }) {
		if (getters.offergroupSpecOptions &&
			state.filter.offerspec &&
			!getters.offergroupSpecOptions.find(e => e.id === state.filter.offerspec.id)
		) {
			commit('setFilter', { name: 'offerspec', filter: null });
		}
	},

	async getAnimalkindOptions({ rootState, state, dispatch, commit }) {
		let country = rootState.country.currentCountry;
		if (state.animalkindOptions) return;
		let ids = country.data.enabledAnimals;
		let list = await dispatch('admin/loadEntities', { ids, throwIfNotAllFound: true }, { root: true });
		commit('setOptions', { name: 'animalkind', list });
	},

	async getAnimalmenus({ rootState, state, commit }) {
		if (state.animalmenus.length) return;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/countries',
			comparison: 'eq',
			value: rootState.country.currentCountry.id
		});
		let ec = await getEntityclassByExternalId('animalmenu');
		let list = await search(ec.id, attributeFilter);
		commit('setState', { name: 'animalmenus', list });
	},

	async setAnimalusageOptions({ state, commit, rootState }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let country = rootState.country.currentCountry.id;
		let animalkind = state.filter.animalkind;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/countries',
			comparison: 'eq',
			value: country
		});
		attributeFilter.$and.push({
			field: '/data/animalkinds',
			comparison: 'eq',
			value: animalkind
		});
		let ec = await getEntityclassByExternalId('animalusage');
		//will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		commit('setOptions', { name: 'animalusage', list });
		//update current filter
		let currentFilter = state.filter.animalusage;
		if (Array.isArray(currentFilter)) {
			let filtered = currentFilter.filter(e => list.find(o => o.id === e.id));
			if (filtered.length < currentFilter.length) commit('setOptions', { name: 'animalusage', filtered });
		}
	},

	async setAgeCategoryOptions({ state, commit, rootState }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let animalkind = state.filter.animalkind;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/animalkinds',
			comparison: 'eq',
			value: animalkind,
		});
		attributeFilter.$and.push({
			field: '/data/country',
			comparison: 'eq',
			value: rootState.country.currentCountry.id,
		});
		let ec = await getEntityclassByExternalId('agecategory');
		// will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		list.sort((a, b) => a.data.sorting - b.data.sorting);
		commit('setOptions', { name: 'ageCategory', list });
		// update current filter
		let currentFilter = state.filter.ageCategory;
		if (currentFilter && !list.find(e => e.id === currentFilter.id)) {
			commit('setFilter', { name: 'ageCategory', filter: null });
		}
	},

	async setCategory2Options({ state, commit, rootState }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let country = rootState.country.currentCountry.id;
		let animalkind = state.filter.animalkind;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/animalkinds',
			comparison: 'eq',
			value: animalkind,
		});
		attributeFilter.$and.push({
			field: '/data/countries',
			comparison: 'eq',
			value: country,
		});
		let ec = await getEntityclassByExternalId('category2');
		// will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		commit('setOptions', { name: 'category2', list });
		// update current filter
		let currentFilter = state.filter.category2;
		if (currentFilter && !list.find(e => e.id === currentFilter.id)) {
			commit('setFilter', { name: 'category2', filter: null });
		}
	},

	async setCategory1Options({ state, commit, rootState }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let country = rootState.country.currentCountry.id;
		let animalkind = state.filter.animalkind;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/animalkinds',
			comparison: 'eq',
			value: animalkind,
		});
		attributeFilter.$and.push({
			field: '/data/country',
			comparison: 'eq',
			value: country,
		});
		let ec = await getEntityclassByExternalId('category1');
		// will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		commit('setOptions', { name: 'category1', list });
		// update current filter
		let currentFilter = state.filter.category1;
		if (currentFilter) {
			let filtered = currentFilter.filter(e => list.find(o => o.id === e.id));
			if (filtered.length < currentFilter.length) commit('setOptions', { name: 'category1', filtered });
		}
	},

	async setAsoOptions({ state, commit, rootState, rootGetters }) {
		if (!state.filter.animalkind || !rootState.country.currentCountry) return;
		let attributeFilter = { $and: [] };
		attributeFilter.$and.push({
			field: '/data/staticData/role',
			comparison: 'eq',
			value: 'ASO',
		});
		attributeFilter.$and.push({
			field: '/data/staticData/country',
			comparison: 'eq',
			value: rootGetters['country/currentCountry'].id,
		});
		let ec = await getEntityclassByExternalId('account');
		// will cache the search and only run it once even if 2 parallel requests come
		let list = await cachedSearch(ec.id, attributeFilter);
		commit('setOptions', { name: 'aso', list });
		// update current filter
		let currentFilter = state.filter.aso;
		if (currentFilter) {
			let filtered = currentFilter.filter(e => list.find(o => o.id === e.id));
			if (filtered.length < currentFilter.length) commit('setOptions', { name: 'aso', filtered });
		}
	},

	async setDynamicFields({ commit, rootState }) {
		if (!rootState.country.currentCountry) return;
		let country = rootState.country.currentCountry.id;
		let attributeFilter = { $and: [] };
		attributeFilter = {
			field: '/data/availableInCountries',
			comparison: 'eq',
			value: country
		};
		let ec = await getEntityclassByExternalId('offerfield');
		let list = await cachedSearch(ec.id, attributeFilter);
		commit('setDynamicFields', list);
	},

	async runFilterRules({ dispatch, rootGetters, rootState, state, commit }) {
		let rs = await dispatch('admin/getRulesetForKey', { key: 'search_fields' }, { root: true });
		if (!rs) return;
		let facts = await dispatch('bbay/evaluate', {
			id: rs.id,
			rev: rs.rev,
			facts: {
				env: {
					country: rootGetters['country/currentCountry'].id,
					modus: state.modus,
					animalkind: state.filter.animalkind,
					user: rootGetters['auth/user/role'].toLowerCase()
				},
				input: {
					requestType: rootState.request.main.selectedType
				},
				visible: {},
				mandatory: {},
				calendar: {},
				location: {},
				searchfilter: {},
				displayHint: {}
			}
		}, { root: true });
		if (facts.visible.showhistory) {
			facts.visible.history = facts.visible.showhistory;
			delete facts.visible.showhistory;
		}
		commit('setFilterVisible', facts.visible || {});
		commit('setFilterMandatory', facts.mandatory || {});
		commit('setCalendarSettings', facts.calendar);
		Object.entries(facts.searchfilter).forEach(e => {
			let val = e[0] === 'gender' ? e[1].value : { id: e[1].value };
			commit('setFilter', { name: e[0], filter: val });
		});
		commit('setDisplayHints', facts.displayHint);
		if ('requestGeoRadius' in facts.location) commit('setDefaultLocationGeoRadius', facts.location.requestGeoRadius);
		commit('setGeoRadiusByRegion', facts.location);
		commit('setRequestOptionsFilter', facts.options);
		commit('setGeneralFilterOptions', facts);
	},

	async switchOfferToHistory({ commit }, { groupId, offerId, historyDate }) {
		let historic = null;
		if (historyDate) {
			historic = await getEntityByIdDate(offerId, `${historyDate} 23:59:59`);	//is already cached
		}
		commit('setOfferHistoric', { groupId, offerId, historic });
	},

	async init({ commit, state, getters, dispatch }) {
		let currentModus = state.modus;
		commit('resetAllFilters');
		let newModus = state.requestMode ? 'request'
			: !getters.usesPoultrySystem ? 'default'
				: state.spotMarketSwitch ? 'poultry-spot'
					: 'poultry-guest';
		if (currentModus !== newModus) {
			commit('setModus', newModus);
		}
		if (getters.usesPoultrySystem && newModus !== 'poultry-spot') {
			commit('setFilter', {
				name: 'statusWeekly',
				filter: getters.startOfIsoWeek,
			});
		}
		commit('setFilter', {
			name: 'amountComparison',
			filter: getters.amountComparisonOptions[0]
		});
		await dispatch('runFilterRules');
		if (state.modus !== 'request') await dispatch('loadOfferSummary');
	}

};

const mutations = {

	setOptions(state, { name, list }) {
		let key = `${name}Options`;
		if (key in state) {
			state[key] = list;
		} else {
			throw new Error(`unknown options: ${name}`);
		}
	},

	setState(state, { name, list }) {
		if (name in state) {
			state[name] = list;
		} else {
			throw new Error(`unknown state: ${name}`);
		}
	},

	setFilter(state, { name, filter }) {
		if (name in state.filter) {
			state.filter[name] = filter;
			state.loaded = false;
			if (name === 'offergroup') {
				if (filter && filter.data.category2) {
					let opt = state.category2Options.find(e => e.id === filter.data.category2) || null;
					state.filter.category2 = opt;
				} else {
					state.filter.category2 = null;
				}
			} else if (name === 'history' && filter && state.filter.historyTill) {
				let from = new Date(filter);
				let till = new Date(state.filter.historyTill);
				if (from > till) state.filter.historyTill = null;
			}
		} else {
			throw new Error(`unknown filter: ${name}`);
		}
	},

	resetFilter(state, name) {
		if (name in state.filter) {
			let val = state.filter[name];
			if (Array.isArray(val)) {
				if (val.length > 0) state.filter[name] = [];
			} else if (val) state.filter[name] = null;
		} else {
			let existing = state.filter.dynamic.findIndex(e => e.id === name);
			if (existing > -1) state.filter.dynamic.splice(existing, 1);
		}
		state.loaded = false;
	},

	setLocation(state, location) {
		state.filter.location = location;
	},

	setGroupedResult(state, res) {
		state.offerListGrouped = res;
		state.loaded = true;
	},

	addLoadedOffers(state, { groupId, offers }) {
		let arr = state.offerListGrouped[groupId].offers;
		state.offerListGrouped[groupId].offers = arr.concat(offers);
	},

	setOfferHistoric(state, { groupId, offerId, historic }) {
		const offers = state.offerListGrouped[groupId].offers;
		const offer = offers.find(o => o.id === offerId);
		if (!offer) throw new Error('unable to find offer with grp: '+groupId+' / id: '+offerId);
		if (!offer.historic && historic) {
			Vue.set(offer, 'historic', historic);
		}
		Vue.set(offer, 'showHistoric', !!historic);
	},

	setAnimalkind(state, ak) {
		if (!ak) throw new Error('animalkind cannot be undefined');
		state.filter.animalkind = ak;
		state.offerListGrouped = [];
		state.loaded = false;
	},

	setDynamicFields(state, list) {
		state.dynamicFields = list;
	},

	setDynamicFieldValue(state, { id, value }) {
		let existing = state.filter.dynamic.findIndex(e => e.id === id);
		if (existing > -1) {
			if (typeof value === 'undefined' || value === null) {
				state.filter.dynamic.splice(existing, 1);
			} else {
				state.filter.dynamic[existing].value = value;
			}
			state.loaded = false;
		} else {
			state.filter.dynamic.push({ id, value });
		}
	},

	setModus(state, modus) {
		state.modus = modus;
	},

	setSpotMarketSwitch(state, sw) {
		state.spotMarketSwitch = sw;
	},

	setRequestModus(state, m) {
		state.requestMode = m;
	},

	resetAllFilters(state) {
		Object.keys(state.filter).forEach(key => {
			if (['animalkind', 'locationGeoRadius', 'amountComparison'].includes(key)) return;
			if (key === 'location' && state.requestMode) return;
			let val = state.filter[key];
			if (Array.isArray(val)) {
				if (val.length > 0) state.filter[key] = [];
			} else if (val) state.filter[key] = null;
		});
		state.loaded = false;
		state.offerListGrouped = [];
	},

	resetMode(state) {
		state.requestMode = false;
		state.spotMarketSwitch = false;
	},

	setFilterVisible(state, filter) {
		state.filterVisible = filter;
	},

	setFilterMandatory(state, filter) {
		state.filterMandatory = filter;
	},

	setCalendarSettings(state, settings) {
		state.calendarSettings = settings;
	},

	setDisplayHints(state, hints) {
		state.displayHints = hints;
	},

	setDefaultLocationGeoRadius(state, radius) {
		state.defaultLocationGeoRadius = radius;
	},

	setGeoRadiusByRegion(state, regions) {
		state.geoRadiusByRegion = regions;
	},

	setRequestOptionsFilter(state, options) {
		state.requestOptionsFilter = options;
	},

	setFilterExport(state, ex) {
		state.filterExport = !!ex;
	},

	setGeneralFilterOptions(state, facts) {
		state.allowTransnationalSearch = facts.transnational && facts.transnational.enabled;
	},

	setOfferGroupOpen(state, { group, open }) {
		if (open) {
			if (!state.offerGroupsOpen.includes(group)) {
				state.offerGroupsOpen.push(group);
			}
		} else {
			let idx = state.offerGroupsOpen.indexOf(group);
			if (idx > -1) {
				state.offerGroupsOpen.splice(idx, 1);
			}
		}
	},

	resetOfferGroupsOpen(state) {
		state.offerGroupsOpen = [];
	}

};

function filterAutApproval(attributeFilter, rootGetters) {
	let filter = JSON.parse(JSON.stringify(attributeFilter));
	if (rootGetters['country/currentCountry'].data.offerAutApproval) {
		filter.$and.push({
			$not: {
				field: '/data/autApproval',
				comparison: 'eq',
				value: 'pending',
			},
		}, {
			$not: {
				field: '/data/autApproval',
				comparison: 'eq',
				value: 'rejected',
			},
		});
	}
	return filter;
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
