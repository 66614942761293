// import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import './plugins/vuelidate';
import './plugins/icons';
import './plugins/filters';
import './plugins/leaflet';
import './plugins/module-css';
import { i18n } from './plugins/i18n';
import '@/assets/scss/custom.scss';
import App from './App.vue';
import { createRouter } from './router';
import store from './store';
import { initialize } from '@/util/initialize.js';
import EventHub from '@/util/eventbus.js';

initialize()
	.then(() => {
		let prefix = store.getters['country/urlPrefix'];
		let admin = store.getters['country/isAdmin'];
		return createRouter(prefix, admin);
	}).then(router => {
		EventHub.$on('navigate', route => {
			router.push(route);
		});
		new Vue({
			router,
			store,
			render: h => h(App),
			i18n
		}).$mount('#app');
	});

Vue.config.productionTip = false;
