import Store from '@/store/index.js';
import { toLocale, toAccountName } from '@/plugins/filters.js';
import { search } from '@/repository/system.js';

function loadList(entityclass, attributeFilter) {
	if (attributeFilter) {
		return Store.dispatch('admin/loadEntityclass', entityclass)
			.then(ec => {
				return search(ec, attributeFilter);
			}).then(list => {
				return {
					entityclass,
					attributeFilter,
					list
				};
			});
	} else {
		return Store.dispatch('admin/loadList', { type: entityclass })
			.then(list => {
				return {
					entityclass,
					list
				};
			});
	}
}

export function getCachedOptionKey({ entityclass, attributeFilter }) {
	return `${entityclass}${attributeFilter ? JSON.stringify(attributeFilter) : ''}`;
}

export async function loadEcForPath(paths) {
	let promises = [];
	for (let e of paths) {
		if (e.keys) {
			for (let key of Object.keys(e.keys)) {
				if (e.keys[key].entityclass) {
					promises.push(loadList(e.keys[key].entityclass));
				}
			}
		}
		if (e.options) {
			if (e.options.entityclass) {
				promises.push(loadList(e.options.entityclass, e.options.attributeFilter));
			}
		}
	}
	let res = await Promise.all(promises);
	return res.reduce((c, { entityclass, attributeFilter, list }) => {
		let key = getCachedOptionKey({ entityclass, attributeFilter });
		c[key] = list;
		return c;
	}, {});
}

export function calculatePathOptions(paths, pathOptionsEc) {
	let ret = [];
	paths.forEach(e => {
		if (e.options && e.options.entityclass) {
			let ecKey = getCachedOptionKey(e.options);
			if (!(ecKey in pathOptionsEc)) {
				console.log('ecKey missing', ecKey, pathOptionsEc);
			}
			e.options.loaded = pathOptionsEc[ecKey];
		}
		if (e.keys) {
			const keys = buildKeys(e, pathOptionsEc);
			cartesianProduct(keys).forEach(elem => {
				let path = e.path;
				let name = `${e.name} (`;
				let nameAfter = '';
				let variables = [];
				let filterable = {};
				elem.forEach(el => {
					if (el.filter) Object.assign(filterable, el.filter);
					for (let [key, val] of Object.entries(el)) {
						// replace '.../:key/...' with '.../value/...' and '.../:key' with '.../value'
						let regex = new RegExp(`/:${key}(/)|/:${key}$`, 'gu');
						path = path.replace(regex, `/${val}$1`);
						name += `${nameAfter}${el.label}`;
						nameAfter = ', ';
						variables.push(val);
					}
				});
				name += ')';
				ret.push({ path, name, opt: e.options, variables, filterable });
			});
		} else {
			ret.push({ path: e.path, json: { var: e.path }, name: e.name, opt: e.options });
		}
	});
	return ret;
}

function cartesianProduct(arr) {
	let ret = [];
	if (!arr || arr.length === 0) return arr;
	let arr1 = arr.splice(0, 1)[0];
	arr = cartesianProduct(arr);
	for (let i = 0, { length } = arr1; i < length; i++) {
		if (arr && arr.length) {
			for (let j = 0, len = arr.length; j < len; j++) {
				ret.push([arr1[i]].concat(arr[j]));
			}
		} else {
			ret.push([arr1[i]]);
		}
	}
	return ret;
}

function buildKeys(e, pathOptionsEc) {
	let keys = [];
	for (let key of Object.keys(e.keys)) {
		let regex = new RegExp(`/:${key}/|/:${key}$`, 'gu');
		if (regex.test(e.path)) {
			if (e.keys[key].enum) {
				keys.push(buildEnumKey(e.keys[key].enum, key));
			} else if (e.keys[key].entityclass) {
				let ecKey = getCachedOptionKey(e.keys[key]);
				if (pathOptionsEc[ecKey]) {
					keys.push(buildListKey(key, pathOptionsEc[ecKey]));
				}
			} else if (e.keys[key].numRange) {
				keys.push(buildRangeKey(e.keys[key].numRange, key));
			}
		}
	}
	return keys;
}

function buildEnumKey(en, key) {
	return en.map(map => {
		let e = { [key]: map };
		Object.defineProperty(e, 'label', {
			enumerable: false,
			value: map
		});
		return e;
	});
}

function buildListKey(key, options) {
	return options.map(obj => {
		let e = { [key]: obj.id };
		let label = '';
		if (obj.data.labels && obj.data.labels.length) label = toLocale(obj.data.labels);
		else if (obj.data.internalName) label = obj.data.internalName;
		else if (obj.data.registrationOptionName) label = toAccountName(obj.data);
		if (!label) {
			console.log('unable to find label', obj);
			label = obj.id;
		}
		let filter = {};
		let addToFilter = (key, val) => {
			if (Array.isArray(val)) {
				return val.forEach(v => addToFilter(key, v));
			}
			if (!(key in filter)) {
				filter[key] = [];
			}
			if (!filter[key].includes(val)) {
				filter[key].push(val);
			}
		};
		if (obj.data.country) {
			addToFilter('country', obj.data.country);
		}
		if (obj.data.countries) {
			addToFilter('country', obj.data.countries);
		}
		if (obj.data.animalkind) {
			addToFilter('animalkind', obj.data.animalkind);
		}
		if (obj.data.animalkinds) {
			addToFilter('animalkind', obj.data.animalkinds);
		}
		if (obj.data.availableInCountries) {
			addToFilter('country', obj.data.availableInCountries);
		}
		Object.defineProperties(e, {
			label: {
				enumerable: false,
				value: label
			},
			filter: {
				enumerable: false,
				value: filter
			}
		});
		return e;
	});
}

function buildRangeKey(numRange, key) {
	let min = numRange.minimum || 0;
	let max = numRange.maximum || 10;
	let vals = [];
	for (let i=min; i<=max; i++) {
		let e = { [key]: i };
		Object.defineProperty(e, 'label', {
			enumerable: false,
			value: i
		});
		vals.push(e);
	}
	return vals;
}
