import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { languages } from '@/assets/translations/index.js';
import Common from 'tdbcommon';

/*
 * translation setup
 */

Vue.use(VueI18n);

const fallbackTree = {};
Common.Config.Languages.List.forEach(lang => {
	let list = Common.Config.Languages.getLanguagePriority(lang);
	list.shift();	//remove lang
	fallbackTree[lang] = list;
});

export const i18n = new VueI18n({
	locale: 'de_DE',
	fallbackLocale: fallbackTree,
	messages: { ...languages }
});

export default i18n;
