const LIMIT = 10; // how many notifications are saved, 0 for no limitation

const state = {
	notifications: [],
	notificationsToShow: [],
};

const getters = {
	get: (state) => {
		return state.notifications;
	},
	getNotShown: (state) => {
		return state.notificationsToShow;
	},
};

const mutations = {
	add(state, notification) {
		state.notifications.push(notification);
		state.notificationsToShow.push(notification);
		let len = state.notifications.length;
		if (LIMIT && len > LIMIT) {
			state.notifications = state.notifications.slice(len-LIMIT, len);
		}
	},
	shiftToShow() {
		state.notificationsToShow = state.notificationsToShow.slice(1);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
