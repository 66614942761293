import { get, post } from '@/util/request.js';
import { getEntityByIdRev, getEntityById } from './system.js';
import { Request } from 'tdbcommon';

export function setSettings(data) {
	return post('tdb/settings/update', data);
}

export function getSettings() {
	return get('tdb/settings/get');
}

export function refreshAso() {
	return get('tdb/user/refreshaso');
}

export function notifyMissingOffergroup(name, animalkind) {
	return post('tdb/notify/missingoffergroup', { name, animalkind });
}

export function contactPageRequest(name, email, phone, controlNumber, subject, message, country) {
	return post('tdb/notify/contactrequest', { name, email, phone, controlNumber, subject, message, country });
}

export function registerAgr(data) {
	return post('tdb/register/agr', data);
}

export function requestInit(data) {
	return post('tdb/request/initrequest', data);
}

export function requestFill(data) {
	return post('tdb/request/fillform', data);
}
export function requestRemove(data) {
	return post('tdb/request/remove', data);
}

export function retractProcess(data) {
	return post('tdb/request/retractrequest', data);
}

export async function loadFieldForPackage(pkg) {
	let list = pkg.getSteps().flatMap(step => step.getFields())
		.filter(function(value, index, self) {
			let exists = self.find(e => e.id === value.id && e.rev === value.rev);
			return self.indexOf(exists) === index;
		});
	let res = await Promise.all(list.map(e => getEntityByIdRev(e.id, e.rev)));
	res = res.map(e => {
		let f = new Request.RequestFormField();
		f.fromStorage(e);
		return f;
	});
	pkg.getSteps().forEach(step => {
		step.setFieldsLoaded(res);
	});
}

export async function loadPackage(id, rev) {
	let entity = await getEntityByIdRev(id, rev);
	let pkg = new Request.RequestPackage();
	pkg.fromStorage(entity);
	await loadFieldForPackage(pkg);
	return pkg;
}


export async function loadProcess(id) {
	let entity = await getEntityById(id);
	let process = new Request.RequestProcess();
	process.fromStorage(entity);
	let pkg = await loadPackage(process.package.id, process.package.rev);
	process.setPackage(pkg);
	return process;
}

export function updateGeoPosition(data) {
	return post('tdb/user/updategeoposition', data);
}

export function setUserAgreement(data) {
	return post('tdb/user/setuseragreement', data);
}

export function setRequestStatus({ process, editorName, inProgress, statusLabel }) {
	return post('tdb/request/setstatus', { process, editorName, inProgress, statusLabel });
}
