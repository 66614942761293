import Store from '@/store/main.js';

Store.watch(
	() => Store.state.offer.search.filter.animalkind,
	() => {
		Store.dispatch('offer/search/setAnimalusageOptions');
		Store.dispatch('offer/search/setOffergroupOptions');
		Store.dispatch('offer/search/setAgeCategoryOptions');
		Store.dispatch('offer/search/setCategory1Options');
		Store.dispatch('offer/search/setCategory2Options');
		Store.dispatch('offer/search/setAsoOptions');

		Store.commit('request/main/setSelectedType', null);
	}
);

Store.watch(
	() => Store.state.offer.search.filter.offergroup,
	() => {
		Store.dispatch('offer/search/checkOfferSpecifications');
	}
);

Store.watch(
	() => Store.state.offer.search.spotMarketSwitch,
	() => {
		Store.dispatch('offer/search/init');
	}
);
