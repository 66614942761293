import { toLocale } from '@/plugins/filters.js';
import { i18n } from '@/plugins/i18n';

export const offerFieldComponent = {
	'select': {
		type: 'multi-select-wrap',
		options: {
			'group-values': 'group',
			'group-label': 'groupLabel',
			'track-by': 'id',
			'label': 'label',
		},
	},
	'multiselect': {
		type: 'multi-select-wrap',
		options: {
			'multiple': true,
			'group-values': 'group',
			'group-label': 'groupLabel',
			'track-by': 'id',
			'label': 'label',
		},
	},
	'singleline-textfield': { type: 'b-form-input' },
	'textfield': { type: 'b-form-textarea' },
	'multitext': { type: 'multi-text', options: { multiline: true }},
	'singleline-multitext': { type: 'multi-text' },
	'checkbox': { type: 'b-form-checkbox' },
	'datefield': { type: 'b-form-datepicker', options: i18n.t('datepicker') },
	'numberfield': { type: 'b-form-input', options: { type: 'number', min: 0 }},
};

export const inputTypeMap = {
	'select': 'selectValue',
	'multiselect': 'multiselectValue',
	'multitext': 'multitextValue',
	'singleline-multitext': 'multitextValue',
	'singleline-textfield': 'stringValue',
	'textfield': 'stringValue',
	'checkbox': 'booleanValue',
	'datefield': 'dateValue',
	'numberfield': 'numberValue'
};

export function getDynamicFieldOptions(field) {
	let options = field.data.options;
	let groups = field.data.groups;
	options = options.filter(x => x.enabled);
	let usedGroups = [];
	let ungrouped;
	options.forEach(opt => {
		if (opt.group && !usedGroups.includes(opt.group)) {
			usedGroups.push(opt.group);
		} else if (!opt.group) ungrouped = true;
	});
	let ret = [];
	usedGroups.forEach(grp => {
		let group = options.filter(opt => opt.group === grp);
		let groupLabel = toLocale(groups?.filter(x => x.id === grp)[0].labels);
		group = group.map(g => ({ id: g.id, label: toLocale(g.labels) }));
		ret.push({ groupLabel, group });
	});
	if (ungrouped) {
		let group = options.filter(opt => !opt.group);
		group = group.map(g => ({ id: g.id, label: toLocale(g.labels) }));
		ret.push({ groupLabel: '-', group });
	}
	return ret;
}
