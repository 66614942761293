import { saveAs } from 'file-saver';
import { i18n } from '@/plugins/i18n';

export function base64EncodeFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export function base64DecodeFile(encodedFile, fileName) {
	let dataUrlParts = encodedFile.split(',');

	let mimeType;
	let decoded;

	if (dataUrlParts.length > 1) {
		mimeType = dataUrlParts[0].match(/:(?:.*?);/u)[1];
		decoded = atob(dataUrlParts[1]);
	} else {
		decoded = atob(dataUrlParts[0]);
	}

	let bits = new Uint8Array(decoded.length);

	for (let i = 0; i < decoded.length; i++) {
		bits[i] = decoded.charCodeAt(i);
	}

	const options = {};
	if (mimeType) {
		options.type = mimeType;
	}

	return new File([bits], fileName, options);
}

export function dataUriToBlob(dataURI) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	let mime = 'application/octet-stream';
	let content;
	if (dataURI.substr(0, 5) === 'data:') {
		let match = dataURI.match(/^data:(?<mime>[\w/\-.]+)(;(?<encoding>\w+))?,(?<data>.*$)/s);
		if (!match) throw new Error(i18n.t('util.invalidDataUri'));
		content = match.groups.encoding === 'base64' ? atob(match.groups.data) : match.groups.data;
		mime = match.groups.mime;
	} else {
		content = dataURI;
	}
	// write the bytes of the string to an ArrayBuffer
	let ab = new ArrayBuffer(content.length);
	// create a view into the buffer
	let ia = new Uint8Array(ab);
	// set the bytes of the buffer to the correct values
	for (let i = 0; i < content.length; i++) {
		ia[i] = content.charCodeAt(i);
	}
	// write the ArrayBuffer to a blob, and you're done
	let blob = new Blob([ab], { type: mime });
	return blob;
}

export function downloadUpload(blob, name) {
	try {
		let isFileSaverSupported = !!new Blob();
	} catch {
		console.log(i18n.t('util.noFileSaverSupport'));
		return;
	}
	saveAs(blob, name);
}
