import { search, getEntityclassByExternalId } from '@/repository/system.js';

const state = {

	list: []

};

const getters = {

	byId: (state) => (id) => {	//latest rev
		return state.list.filter(e => e.id === id).sort((a, b) => b.rev - a.rev)[0];
	},

	byIdRev: (state) => (id, rev) => {
		return state.list.find(e => e.id === id && e.rev === rev);
	},

	latestRevs: (state) => {
		let ids = new Set();
		return state.list.slice(0).sort((a, b) => b.rev - a.rev).filter(e => {
			if (ids.has(e.id)) return false;
			ids.add(e.id);
			return true;
		});
	}

};

const actions = {

	async loadAll({ state, dispatch, commit }) {
		if (state.list.length > 0) return;
		let ec = await getEntityclassByExternalId('requestformfield');
		let res = await search(ec.id);
		commit('setAll', res);
	},

	async get({ state, dispatch, commit }, { id, rev }) {
		let existing = id && rev ? state.list.find(e => e.id === id && e.rev === rev) : null;
		if (existing) return existing;
		let entity = await dispatch('admin/loadEntity', { id, rev }, { root: true });
		commit('addToList', entity);
		return entity;
	},

	getFromStep({ dispatch }, { fields }) {
		return Promise.all(fields.map(f => dispatch('get', f)));
	}

};

const mutations = {

	setAll(state, list) {
		state.list = list;
	},

	addToList(state, entity) {
		let exists = state.list.find(e => e.id === entity.id && e.rev === entity.rev);
		if (exists) return;
		state.list.push(entity);
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
