import { search, getEntityclassByExternalId } from '@/repository/system.js';
import * as Common from 'tdbcommon';

const state = {

	entityclass: null,
	currentCountry: null,
	countrySelection: [],
	urlPrefix: '/',
	list: []

};

function findCountryByUrl(countries) {
	let url = document.location.host + document.location.pathname;
	// console.log('find domain for ', url);
	for (let country of countries) {
		if (!country.data.domains) continue;
		for (let domain of country.data.domains) {
			if (url.substr(0, domain.length) === domain) {
				return { country, domain };
			}
		}
	}
	return {};
}

const getters = {

	isAdmin: (state) => {
		return state.currentCountry?.data.isAdmin;
	},

	countryLabels: (state) => {
		return state.currentCountry ? state.currentCountry.data.labels : [];
	},

	isCountrySelected: (state) => {
		return !!state.currentCountry;
	},

	countriesForSelection: (state) => {
		return state.countrySelection;
	},

	currentCountry: (state) => {
		return state.currentCountry;
	},

	urlPrefix: (state) => {
		return state.urlPrefix;
	},

	usesRequestSystem: state => {
		return state.currentCountry?.data.usesRequestSystem;
	},

	usesSso: state => {
		return state.currentCountry?.data.registrationProcedure !== 'apiagencybiofr';
	},

	countriesForOptions: state => {
		return state.list.filter(c => !c.data.isAdmin);
	},

	byId: state => id => {
		return state.list.find(c => c.id === id);
	}
};

const actions = {

	async loadCurrentCountry({ commit, state, dispatch, rootState }) {
		await dispatch('loadEntityclass');
		let filter = {
			$or: [{
				field: '/data/enabledForWebsite',
				comparison: 'eq',
				value: true
			}, {
				field: '/data/isAdmin',
				comparison: 'eq',
				value: true
			}]
		};
		let res = await search(state.entityclass, filter);
		let { country, domain } = findCountryByUrl(res);
		if (!country && res.length === 1) {
			country = res[0];
			domain = country.data.domains[0];
		}
		if (country) {
			if (country.data.isAdmin) {
				country.data.languages = Common.Config.Languages.List;
			}
			let url = new URL(`http://${domain}`);
			// console.log('setting current domain', url);
			commit('setCountry', { country, prefix: url.pathname });
			if (country.data.languages.length === 1 ||
				country.data.languages.length > 0 && !country.data.languages.includes(rootState.base.uiLocale)) {
				commit('base/setUiLocale', country.data.languages[0], { root: true });
			}
		}
	},

	async loadCountrySelection({ commit, state, dispatch }) {
		await dispatch('loadEntityclass');
		let filter = {
			field: '/data/enabledForWebsite',
			comparison: 'eq',
			value: true
		};
		let res = await search(state.entityclass, filter);
		commit('setCountries', res);
	},

	async init({ state, dispatch }) {
		await dispatch('loadCurrentCountry');
		if (!state.currentCountry) {
			await dispatch('loadCountrySelection');
		}
	},

	async loadEntityclass({ state, commit }) {
		if (!state.entityclass) {
			let ec = await getEntityclassByExternalId('country');
			commit('setEntityclass', ec);
		}
	},

	async loadAll({ state, dispatch, commit }) {
		if (state.list.length > 0) return;
		await dispatch('loadCurrentCountry');
		let res = await search(state.entityclass);
		commit('setAll', res);
	}

};

const mutations = {

	setEntityclass(state, { id }) {
		state.entityclass = id;
	},

	setCountry(state, { country, prefix }) {
		state.currentCountry = country;
		state.urlPrefix = prefix;
	},

	setCountries(state, list) {
		state.countrySelection = list;
	},

	setAll(state, list) {
		state.list = list;
	},

	addAll(state, list) {
		list.forEach(c => {
			if (!state.list.find(e => e.id === c.id)) {
				state.list.push(c);
			}
		});
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
