import debounce from 'debounce';

const state = {

	idsWanted: [],
	idsUnavailable: [],
	entities: []

};


const getters = {

	byId: state => id => {
		return state.entities.find(e => e && e.id === id);
	}

};

const actions = {

	addRequiredEntity({ state, commit, dispatch }, { id }) {
		if (state.idsWanted.includes(id) ||
			state.idsUnavailable.includes(id) ||
			state.entities.find(e => e.id === id)) return;
		commit('addId', id);
		dispatch('debouncedLoad');
	},

	debouncedLoad: debounce(async function loadRelated({ state, commit, dispatch }) {
		let ids = JSON.parse(JSON.stringify(state.idsWanted));
		if (ids.length === 0) return;
		let res = await dispatch('admin/loadEntities', { ids, throwIfNotAllFound: false }, { root: true });
		let unavailable = [];
		res = res.filter((e, i) => {
			if (!e) {
				unavailable.push(ids[i]);
			}
			return !!e;
		});
		if (unavailable.length > 0) {
			commit('addUnavailable', unavailable);
		}
		commit('clearIds', ids);
		commit('addEntities', res);
	}, 20)

};

const mutations = {

	addId(state, id) {
		state.idsWanted.push(id);
	},
	addEntities(state, entities) {
		state.entities = state.entities.concat(entities);
	},
	clearIds(state, ids) {
		state.idsWanted = state.idsWanted.filter(id => !ids.includes(id));
	},
	clearAllIds(state) {
		state.idsWanted = [];
	},
	clearEntities(state) {
		state.entities = [];
	},
	addUnavailable(state, ids) {
		state.idsUnavailable = state.idsUnavailable.concat(ids);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
