import { search } from '@/repository/system.js';
import { createPatch } from 'rfc6902';

const state = {

	loaded: []

};

const getters = {

	label: (state, getters, rootState) => (key) => {
		let lang = rootState.base.uiLocale;
		return getters.labelForLang(key, lang);
	},

	labelForLang: (state) => (key, lang) => {
		let pkg = state.loaded.find(e => e.data.language === lang);
		if (!pkg) return '';
		let items = pkg.data.entries;
		let item = items.find(e => e.key === key);
		if (!item) return '';
		return item.label;
	},

	hasChanged: (state) => ({ language, entries }) => {
		let oldPkg = state.loaded.find(e => e.data.language === language);
		if (!oldPkg) return entries.length > 0;
		let patch = createPatch(oldPkg.data, { language, entries });
		return patch.length > 0;
	},

	languagesLoaded: (state) => {
		return state.loaded.map(e => e.data.language);
	},

	knownKeys: (state) => {
		return Array.from(new Set(state.loaded.map(e => {
			return e.data.entries.map(ee => ee.key);
		}).flat()));
	}

};

const actions = {

	async loadLanguage({ commit, dispatch }, { language }) {
		if (state.loaded.find(e => e.data.language === language)) return;
		let ec = await dispatch('admin/loadEntityclass', 'i18nlabels', { root: true });
		let list = await search(ec, { field: '/data/language', comparison: 'eq', value: language }, { limit: 1 });
		commit('addData', list);
	},

	async loadAllLanguages({ commit, dispatch }) {
		let ec = await dispatch('admin/loadEntityclass', 'i18nlabels', { root: true });
		let list = await search(ec, null, { limit: 100 });
		commit('addData', list);
	},

	async save({ commit, state, dispatch }, { language, entries }) {
		let oldPkg = state.loaded.find(e => e.data.language === language);
		let res = await dispatch('admin/saveEntry', { entity: oldPkg, newData: { language, entries }, type: 'i18nlabels' }, { root: true });
		if (res) commit('addData', [res]);
	},



};

const mutations = {

	addData(state, packages) {
		for (let pkg of packages) {
			let exists = state.loaded.findIndex(e => e.data.language === pkg.data.language);
			if (exists > -1) {
				state.loaded.splice(exists, 1);
			}
			state.loaded.push(pkg);
		}
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
