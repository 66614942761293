import { DOMAIN } from '../util/env';
const storage = window.localStorage;

export function getLastImportJob() {
	return storage.getItem(`${DOMAIN}_last_import_id`);
}

export function setLastImportId(id) {
	storage.setItem(`${DOMAIN}_last_import_id`, id);
}

export function saveRequestFormDraft(processId, data) {
	let dataKey = `${DOMAIN}_rfdd_${processId}`;
	let expiresKey = `${DOMAIN}_rfdt_${processId}`;
	if (data) {
		storage.setItem(dataKey, JSON.stringify(data));
		storage.setItem(expiresKey, Date.now() + 1000 * 60 * 60 * 24 * 8);
	} else {
		storage.removeItem(dataKey);
		storage.removeItem(expiresKey);
	}
}

export function getRequestFormDraft(processId) {
	let dataKey = `${DOMAIN}_rfdd_${processId}`;
	let str = storage.getItem(dataKey);
	if (!str) return null;
	try {
		return JSON.parse(str);
	} catch (e) {
		console.warn(e);
		return null;
	}
}

export function cleanRequestFormDrafts() {
	const now = Date.now();
	let checkKey = `${DOMAIN}_rfdt_`;
	for (let i=0; i<storage.length; i++) {
		let key = storage.key(i);
		if (!key.startsWith(checkKey)) continue;
		let expires = storage.getItem(key);
		if (expires > now) continue;
		let processId = key.split('_')[1];
		let dataKey = `${DOMAIN}_rfdd_${processId}`;
		storage.removeItem(dataKey);
	}
}

export function setLastUsedAccountId(country, id) {
	storage.setItem(`${DOMAIN}_last_account_${country}`, id);
}

export function getLastUsedAccountId(country) {
	return storage.getItem(`${DOMAIN}_last_account_${country}`);
}
