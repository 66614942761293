import { create, update, get, list, run, test } from '@/repository/bbay.js';
import { calculatePathOptions, loadEcForPath } from '@/util/bbay.js';
import moment from 'moment';


const state = {

	rulesets: [],
	initialized: false,
	currentId: null,
	pathOptions: [],
	changed: false,
	sortRules: {
		attribute: 'updated',
		direction: 'desc'
	},
	cache: [],
	options: {
		agecategory: null,
		category1: null,
		category2: null
	}
};


const getters = {

	rulesets: (state) => {
		return state.rulesets;
	},

	currentId: (state) => {
		return state.currentId;
	},

	ruleset: (state) => {
		let id = state.currentId;
		if (!state.initialized || !id) return null;
		return state.rulesets.find(elem => elem.id === id);
	},

	changed: (state) => {
		return state.changed;
	},

	cachedDecision: (state) => (key) => {
		return state.cache.find(e => e.key === key)?.output;
	},

	sortRules: (state) => {
		return state.sortRules;
	},

	byId: (state) => (id) => {
		return state.rulesets.find(e => e.id === id);
	},

	filteredPathOptions: (state) => (ctx) => {
		// console.log('filtered options for', ctx);
		return state.pathOptions.filter(e => {
			// if (e.filterable) console.log(JSON.stringify(e.filterable));
			if (e.filterable) {
				for (let [key, val, negated] of ctx) {
					if (!e.filterable[key]) continue;
					let res = e.filterable[key].includes(val);
					if (res === negated) {
						return false;
					}
				}
			}
			return true;
		});
	}
};

const actions = {

	async getAll({ commit }) {
		let res = await list();
		commit('setRulesets', res);
	},

	async init({ commit, state, dispatch }) {
		if (state.initialized) return;
		commit('initialized');
		let options = {};
		options.agecategory = await dispatch('admin/loadList', { type: 'agecategory' }, { root: true });
		options.category1 = await dispatch('admin/loadList', { type: 'category1' }, { root: true });
		options.category2 = await dispatch('admin/loadList', { type: 'category2' }, { root: true });
		options.animalusage = await dispatch('admin/loadList', { type: 'animalusage' }, { root: true });
		options.formfields = await dispatch('admin/loadList', { type: 'requestformfield' }, { root: true });
		commit('setOptions', options);
		await dispatch('getAll');
	},

	async update({ commit, state, getters }) {
		let r = getters.ruleset;
		let rules = r.rules.map(e => {
			// eslint-disable-next-line no-unused-vars
			let { updated, ...rest } = e; // remove updated
			return rest;
		});
		let ruleset = await update({ id: state.currentId, name: r.name, rules });
		commit('setRuleset', ruleset);
	},

	async setCurrentId({ commit, getters }, { id }) {
		let rs = getters.byId(id);
		let pathOptionsEc = await loadEcForPath(rs.paths);
		let pathOptions = calculatePathOptions(rs.paths, pathOptionsEc);
		commit('setOpenRuleset', { id, pathOptions });
	},

	async evaluate({ commit, getters }, { id, alias, rev, facts }) {
		let inputKey = JSON.stringify({ id, alias, rev, facts });
		let output = getters.cachedDecision(inputKey);
		if (!output) {
			let res = await run({ ruleset: { id, rev, alias }, facts });
			console.log('bbay eval', { id, alias, rev, facts, output, res });
			output = res.facts;
			commit('addToResultCache', { key: inputKey, output });
		}
		return output;
	}

};

const mutations = {

	setRulesets(state, rulesets) {
		state.rulesets = rulesets;
		this.commit('bbay/sortRules');
	},

	setRuleset(state, ruleset) {
		//will always exist because we never create new ones
		let index = state.rulesets.findIndex(elem => elem.id === state.currentId);
		state.rulesets.splice(index, 1, ruleset);
		this.commit('bbay/sortRules');
	},

	setRule(state, { ruleIndex, rule }) {
		let index = state.rulesets.findIndex(elem => elem.id === state.currentId);
		state.rulesets[index].rules.splice(ruleIndex, 1, rule);
		this.commit('bbay/sortRules');
	},

	deleteRule(state, ruleIndex) {
		let index = state.rulesets.findIndex(elem => elem.id === state.currentId);
		state.rulesets[index].rules.splice(ruleIndex, 1);
	},

	setOpenRuleset(state, { id, pathOptions }) {
		state.currentId = id;
		state.pathOptions = pathOptions;
		this.commit('bbay/sortRules');
	},

	initialized(state) {
		state.initialized = true;
	},

	changed(state, changed = true) {
		state.changed = changed;
	},

	addToResultCache(state, { key, output }) {
		state.cache.push({ key, output });
		if (state.cache.length > 50) state.cache.shift();
	},

	setSortRules(state, { attribute, direction }) {
		let sort = {};
		sort.attribute = attribute || state.sortRules.attribute;
		sort.direction = direction || state.sortRules.direction;
		state.sortRules = sort;
		this.commit('bbay/sortRules');
	},

	sortRules(state) {
		let index = state.rulesets.findIndex(elem => elem.id === state.currentId);
		if (index > -1) {
			let smaller = state.sortRules.direction === 'desc' ? -1 : 1;
			let bigger = smaller * -1;
			state.rulesets[index].rules = state.rulesets[index].rules.sort((el1, el2) => {
				if (state.sortRules.attribute === 'name') {
					let name1 = el1.name.toUpperCase();
					let name2 = el2.name.toUpperCase();
					if (name1 < name2) return smaller;
					if (name1 > name2) return bigger;
					return 0;
				}
				if (moment(el1.updated).isSame(el2.updated)) return 0;
				if (moment(el1.updated).isBefore(el2.updated)) return smaller;
				return bigger;
			});
		}
	},

	setOptions(state, opts) {
		Object.keys(opts).forEach(key => {
			state.options[key] = opts[key];
		});
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
