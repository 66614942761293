import { get, post } from '@/util/request.js';
import { getToken } from '@/util/jwt-cache.js';

export async function listAccountsForUser() {
	let auth = getToken();
	if (!auth) return [];
	let list = await get('auth/domain/listaccounts', null, auth);
	return list;
}

export async function registerWithDomainByInvite(inviteId, registrationName, token) {
	const payload = {
		inviteId,
		registrationName,
		userData: {},
		token
	};
	let { account } = await post('auth/domain/register', payload);
	return account;
}

export function refreshToken() {
	return post('auth/account/refreshtoken', null, getToken());
}

export function getAccountDetails() {
	return get('auth/account/getaccount', null, getToken());
}

export function unregisterAccount(account) {
	return post('auth/account/unregisterwithaccount', { account }, getToken());
}

export function updateAccount(data, surpressError) {
	return post('auth/account/updateaccount', data, getToken(), surpressError);
}

export async function loginToDomain(token, useAccount) {
	let p = { token };
	if (useAccount) p.useAccount = useAccount;
	try {
		return await post('auth/domain/login', p);
	} catch (e) {
		if (e.message.includes('unable to choose account') && useAccount) {
			return loginToDomain(token);
		} else {
			throw e;
		}
	}
}
