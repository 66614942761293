import de_DE from './de_DE.json';
import da_DK from './da_DK.json';
import en_GB from './en_GB.json';
import fr_FR from './fr_FR.json';
import sv_SE from './sv_SE.json';
import de_LU from './de_LU.json';
import lt_LT from './lt_LT.json';
import en_IE from './en_IE.json';
import nl_NL from './nl_NL.json';
import en_XI from './en_XI.json';

export const languages = {
	de_DE,
	da_DK,
	en_GB,
	fr_FR,
	sv_SE,
	de_LU,
	lt_LT,
	en_IE,
	en_XI,
	nl_NL
};
