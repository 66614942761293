<template>
	<b-dropdown-group
		:header="$t('menu.user')"
	>
		<template v-if="isLoggedIn">
			<b-dropdown-text>
				{{ accountName }}
				<template v-if="userDeactivated(accountDetails)">
					{{ $t('menu.accountDeactivated') }}
				</template>
			</b-dropdown-text>
			<b-dropdown-item
				:to="{ name: 'Profile' }"
				v-if="showProfile"
			>
				<b-icon-gear-fill scale="0.8" /> {{ $t('menu.profile') }}
			</b-dropdown-item>
			<b-dropdown-item-button
				@click="logout"
				variant="secondary"
			>
				<b-icon-door-closed scale="0.9" /> {{ $t('menu.logout') }}
			</b-dropdown-item-button>
			<b-dropdown-group
				:header="$t('menu.switchTo')"
				v-if="switchableAccounts.length > 0"
			>
				<b-dropdown-item
					v-for="acc in switchableAccounts"
					:key="acc.id"
					@click="switchAccount(acc)"
				>
					{{ toAccountName(acc) }}
					({{ acc.data.staticData.role }})
					<template v-if="userDeactivated(acc.data)">
						{{ $t('menu.accountDeactivated') }}
					</template>
				</b-dropdown-item>
			</b-dropdown-group>
		</template>
		<b-dropdown-item-button
			@click="gotoLogin"
			variant="primary"
			v-else
		>
			{{ $t('menu.loginText') }}
		</b-dropdown-item-button>
	</b-dropdown-group>
</template>


<script>
import { toAccountName } from '@/plugins/filters.js';

export default {
	name: 'Login',
	data() {
		return {
		};
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters['auth/token/isLoggedIn'];
		},
		hasAccount() {
			return this.$store.getters['auth/token/account'] !== null;
		},
		account() {
			return this.$store.getters['auth/token/account'];
		},
		accountName() {
			return this.$store.getters['auth/user/accountName'];
		},
		switchableAccounts() {
			return this.$store.getters['auth/user/availableAccounts'].filter(e => e.id !== this.account);
		},
		showProfile() {
			return this.$store.getters['auth/user/roleSupportsProfile'];
		},
		accountDetails() {
			return this.$store.getters['auth/user/details'];
		}
	},
	methods: {
		gotoLogin() {
			this.$store.dispatch('auth/token/authenticate');
		},
		logout() {
			this.$store.dispatch('auth/token/logout');
		},
		switchAccount(account) {
			this.$store.dispatch('auth/token/switchAccountAndLogin', account.id);
		},
		unregister() {
			this.$store.dispatch('auth/user/unregisterFromAccount');
		},
		userDeactivated(acc) {
			if (!acc.userData?.insData) return false; // userData is absent after login before user is loaded
			for (let insData of acc.userData.insData) {
				if (!insData.deactivatedOn) return false;
			}
			return true;
		},
		toAccountName(acc) {
			let name = toAccountName(acc);
			let data = acc.data || acc;
			if (data.registrationOptionName === 'agr') {
				let ins = data.userData.insData.find(e => !e.deactivatedOn);
				if (!ins) ins = data.userData.insData[0];
				if (ins) {
					name = `${ins.address.name} ${name}`;
				}
			}
			return name;
		}
	}
};
</script>
