<template>
	<b-nav-item-dropdown :text="$t('navigation.menu')" right>
		<login v-if="!adminParty" />
		<b-dropdown-divider />
		<b-dropdown-group
			v-for="group in groups"
			:key="group"
			:header="$t('menu.group.'+group)"
		>
			<b-dropdown-item
				v-for="link in links(group)"
				:key="JSON.stringify({ route: link.route, params: link.params })"
				:active="$route.path === link.path"
				:to="{ name: link.route, params: link.params }"
			>
				{{ link.tl }}
			</b-dropdown-item>
		</b-dropdown-group>
	</b-nav-item-dropdown>
</template>

<script>
import MenuConfig from '@/assets/config/menu.json';
import { onlyUnique } from '@/util/array.js';
import Login from './login.vue';
import { AdminParty } from '@/util/env.js';
/*
 * builds main menu from config
 * will be expanded to filter by user/role
 */
export default {
	name: 'MainNav',
	components: {
		Login,
	},
	data() {
		return {
			adminParty: AdminParty
		};
	},
	computed: {
		groups() {
			return MenuConfig
				.map(e => e.groupKey)
				.filter(onlyUnique)
				.filter(e => this.links(e).length > 0);
		},
		role() {
			return this.$store.getters['auth/user/role'];
		},
		country() {
			return this.$store.getters['country/currentCountry'];
		}
	},
	methods: {
		links(group) {
			return MenuConfig
				.filter(e => e.groupKey === group)
				.filter(e => {
					if (e.excludeRoles) {
						for (let role of e.excludeRoles) {
							if (this.$store.getters['auth/user/hasRole'](role)) return false;
						}
					}
					if (e.countryHas) {
						for (let rule of e.countryHas) {
							if (!this.country.data[rule]) return false;
						}
					}
					if (e.accountNeedsOfferAutApproval) {
						return this.$store.getters['auth/user/offerAutApproval'] === this.country.id;
					}
					if (!e.roles) return true;
					for (let role of e.roles) {
						if (this.$store.getters['auth/user/hasRole'](role)) return true;
					}
					return false;
				}).map(e => {
					e.tl = this.$t(`menu.menu.${e.label}`);
					return e;
				}).sort((a, b) => {
					return a.tl.localeCompare(b.tl);
				});
		}
	}
};
</script>
