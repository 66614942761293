import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faGlobe,
	faTrash
} from '@fortawesome/free-solid-svg-icons';

/*
 * add icons here to make them globally available
 */

library.add(
	faGlobe,
	faTrash
);

Vue.component('v-icon', FontAwesomeIcon);
