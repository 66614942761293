<template>
	<b-nav pills class="select-animal">
		<template
			v-for="top in animalmenusTopLevel"
		>
			<b-nav-item
				v-if="componentType(top) === 'b-nav-item'"
				:to="{ name: 'OfferSearch', params: { id: top.data.animalkind } }"
				:key="top.id"
			>
				{{ topMenuLabel(top) }}
			</b-nav-item>
			<b-nav-item-dropdown
				v-if="componentType(top) === 'b-nav-item-dropdown'"
				:text="topMenuLabel(top)"
				:key="top.id"
				:class="animalkindLabelWhenSubmenu($route.params.id, top) ? 'dropdown-link-active' : null"
			>
				<b-dropdown-item
					v-for="entry in toMenuIds(top.data.submenuItems)"
					:to="{ name: 'OfferSearch', params: { id: entry.data.animalkind } }"
					:key="entry.id"
				>
					{{ entry.data.labels | toLocale }}
				</b-dropdown-item>
			</b-nav-item-dropdown>
			<b-nav-text
				v-if="componentType(top) === 'b-nav-text'"
				:key="top.id"
			>
				{{ topMenuLabel(top) }}
			</b-nav-text>
		</template>
	</b-nav>
</template>


<script>
import { toLocale } from '@/plugins/filters.js';
import { onlyUnique } from '@/util/array.js';

export default {
	name: 'NavAnimalkind',
	data() {
		return {
			subMenus: []
		};
	},

	computed: {
		animalkindOptions() {
			let animalkinds = this.$store.getters['offer/search/animalkindOptions'];
			let animals = [];
			if (animalkinds) animals = JSON.parse(JSON.stringify(animalkinds));
			animals.sort((a, b) => {
				let localeA = toLocale(a.data.labels).toLowerCase();
				let localeB = toLocale(b.data.labels).toLowerCase();
				if (localeA < localeB) return -1;
				else if (localeA > localeB) return 1;
				return 0; // equal
			});
			return animals;
		},
		animalmenus() {
			return this.$store.getters['offer/search/animalmenus'];
		},
		animalmenusTopLevel() {
			return this.animalmenus
				.filter(e => !this.subMenus.includes(e.id))
				.sort((a, b) => a.data.sorting - b.data.sorting);
		}
	},

	async created() {
		await this.$store.dispatch('offer/search/getAnimalkindOptions');
		await this.$store.dispatch('offer/search/getAnimalmenus');
		this.subMenus = this.buildNav();
	},

	methods: {
		toLocaleFn(labels) {
			return toLocale(labels);
		},
		buildNav() {
			let ret = [];
			this.animalmenus.forEach(e => {
				if (e.data.submenuItems) ret = ret.concat(e.data.submenuItems);
			});
			return ret.filter(onlyUnique);
		},
		topMenuLabel(menu) {
			let label = this.toLocaleFn(menu.data.labels);
			if (this.$route.name === 'OfferSearch') {
				let animalLabel = this.animalkindLabelWhenSubmenu(this.$route.params.id, menu);
				if (animalLabel) {
					label += `${this.$t('navAnimalkind.submenuToAnimalkindDivider')}${animalLabel}`;
				}
			}
			return label;
		},
		toMenuIds(ids) {
			if (!ids) return [];
			return ids
				.map(id => this.animalmenus.find(e => e.id === id))
				.filter(e => !!e)
				.sort((a, b) => a.data.sorting - b.data.sorting);
		},
		componentType(menu) {
			if (menu.data.animalkind) return 'b-nav-item';
			if (menu.data.submenuItems) return 'b-nav-item-dropdown';
			return 'b-nav-text';
		},
		animalkindLabelWhenSubmenu(animalkind, menu) {
			let ret = false;
			if (menu.data.submenuItems) menu.data.submenuItems.forEach(e => {
				let submenu = this.animalmenus.find(el => el.id === e);
				if (submenu?.data.animalkind && submenu.data.animalkind === animalkind) {
					let ak = this.animalkindOptions.find(el => el.id === submenu.data.animalkind);
					if (ak) ret = this.toLocaleFn(ak.data.labels);
				}
			});
			return ret;
		}
	}
};
</script>
