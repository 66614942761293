import { search, getEntityclassByExternalId } from '@/repository/system.js';

const state = {

	regions: []

};


const getters = {

	all: (state) => {
		return state.regions;
	},

	forCurrentCountry: (state, getters, rootState, rootGetters) => {
		return state.regions.filter(e => e.data.country === rootGetters['country/currentCountry'].id);
	},

	forCountry: state => countryId => {
		return state.regions.filter(e => e.data.country === countryId);
	}
};

const actions = {

	async loadAll({ commit }) {
		let ec = await getEntityclassByExternalId('region');
		let res = await search(ec.id);
		commit('setRegions', res);
	},

	async loadForCurrentCountry({ commit, rootGetters }) {
		let ec = await getEntityclassByExternalId('region');
		let attributeFilter = {
			$and: [{
				field: '/data/country',
				comparison: 'eq',
				value: rootGetters['country/currentCountry'].id
			}]
		};
		let res = await search(ec.id, attributeFilter);
		commit('setRegions', res);
	},

	async init({ state, dispatch }) {
		if (!state.regions.length) {
			await dispatch('loadAll');
		}
	}

};

const mutations = {

	setRegions(state, list) {
		state.regions = list;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
