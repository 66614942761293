import Store from '@/store/main.js';
import EventHub from '@/util/eventbus.js';

EventHub.$on('page.change', ({ from, to }) => {
	// console.log('page.change', { from, to });
	if (from.name === to.name) return;
	Store.commit('related/clearEntities');
	Store.commit('related/clearAllIds');
	Store.dispatch('admin/clearCache');
});
