<template>
	<div v-html="content"/>
</template>


<script>

export default {

	name: 'PageContent',

	components: {
	},

	filters: {
	},

	props: {
		usage: {
			type: String,
			required: true
		}
	},

	computed: {
		language() {
			return this.$store.state.base.uiLocale;
		},
		languageFallback() {
			return this.$store.state.base.fallbackLocale;
		},
		content() {
			let p = this.$store.getters['page/usage'](this.usage);
			if (!p) return null;
			let { page } = p.data;
			let ret = page.find(e => e.lang === this.language);
			if (!ret) {
				console.log(
					`page-content ${this.usage}:`,
					`no content for '${this.language}',`,
					`trying fallback language '${this.languageFallback}'`
				);
				ret = page.find(e => e.lang === this.languageFallback);
			}
			if (!ret) {
				console.log(`also no content for fallback language '${this.languageFallback}'`);
				return this.$t('pageContent.notFound', [this.usage]);
			}
			return ret.content;
		},
	},

	watch: {
	},

	mounted() {
		this.loadContent();
	},

	methods: {
		loadContent() {
			this.$store.dispatch('page/loadUsage', { usage: this.usage });
		}
	},
};
</script>
