import management from './offer/management.js';
import search from './offer/search.js';

export default {
	namespaced: true,
	modules: {
		management,
		search
	}
};
