<template>
	<div class="spinner-container d-flex justify-content-center">
		<b-spinner
			id="PageLoadingIndicator"
			v-if="opacity > 0"
			style="{ opacity }; padding-top: 3rem; width: 5rem; height: 5rem;"
			:label="$t('loadingIndicatorLabel')"
			variant="primary"
		/>
	</div>
</template>

<script>
import EventBus from '@/util/eventbus.js';
/*
 * shows the LoadingIndicator when a backend request is running
 * fades out because often many requests are chained with only few ms in between
 * to prevent flickering
 */
export default {
	name: 'LoadingIndicator',
	data() {
		return {
			loading: false,
			opacity: 0.0,
			reductionPerTick: 0.05
		};
	},
	created() {
		this.onStart = () => {
			this.opacity = 1.0;
		};
		EventBus.$on('request.start', this.onStart);
		this.onEnd = () => {
			this.tick();
		};
		EventBus.$on('request.end', this.onEnd);
	},
	beforeDestroy() {
		EventBus.$off('request.start', this.onStart);
		EventBus.$off('request.end', this.onEnd);
	},
	methods: {
		tick() {
			if (this.opacity <= 0.0) return;
			this.opacity -= Math.min(this.reductionPerTick, this.opacity);
			window.requestAnimationFrame(() => this.tick());
		}
	}
};
</script>

<style scoped lang="scss">

</style>
