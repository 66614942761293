/*
 * array utility functions
 */

/* use as [].filter to create unique items */
export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export function alphanumericSort(a, b) {
	if (a.text < b.text) return -1;
	if (a.text > b.text) return 1;
	return 0;
}
