import { search, getEntityclassByExternalId, getAttributesetByExternalId,
	genericEntityCreate, genericEntityUpdate, countEntries } from '@/repository/system.js';
import { notifyMissingOffergroup } from '@/repository/tdb.js';
import store from '@/store/index.js';

const state = {

	entityclass: '',
	attributeset: '',
	activtyConfirmedFor: []

};

const getters = {

	countryFilter: (state, getters) => (country) => {
		return country.data.enabledForWebsite &&
			getters.supDataCountries.includes(country.id);
	},

	supDataCountries: (state, getters) => {
		return getters.supData.map(data => data.country);
	},

	supData: (state, getters, rootState, rootGetters) => {
		let sup = rootGetters['auth/user/supData'];
		return sup || [];
	},

};

const actions = {

	async loadOffers({ commit, state }, { start, limit, sorting, attributeFilter }) {
		const type = 'offer';
		if (!state.entityclass) {
			let ec = await getEntityclassByExternalId(type);
			commit('setEntityclass', { value: ec.id });
		}
		let count = await countEntries(state.entityclass, attributeFilter);
		let list = await search(state.entityclass, attributeFilter, { start, limit }, sorting);
		return { count, list };
	},

	async saveOffer({ rootState, state, commit, dispatch }, { entity, newData, updateActivity = true }) {
		const type = 'offer';
		if (!state.entityclass) {
			let ec = await getEntityclassByExternalId(type);
			commit('setEntityclass', { value: ec.id });
		}
		if (!state.attributeset) {
			let as = await getAttributesetByExternalId(type);
			commit('setAttributeset', {value: as.id });
		}
		if (!newData.autApproval && store.getters['country/currentCountry'].data.offerAutApproval) {
			newData.autApproval = 'pending';
		}
		if (!entity) {
			newData.history = [];
		}
		if (!newData.indexData) {
			newData.indexData = {
				ageCategory: [],
				category1: []
			};
		}
		let res;
		if (entity) {
			res = await genericEntityUpdate(entity, newData, state.attributeset);
		} else {
			res = await genericEntityCreate(state.entityclass, state.attributeset, newData);
		}
		if (updateActivity) await dispatch('updateActivity', { countryId: newData.country });
		if (res.data.status !== 'archived' &&
		['AGR', 'SUP'].includes(rootState.auth.user.details.staticData.role)) {
			let { animalkind, country } = res.data;
			await dispatch('updateAnimalkindsSold', { animalkind, country });
		}
		return res;
	},

	async updateActivity({ dispatch, rootState, state, commit }, { countryId }) {
		if (state.activtyConfirmedFor.includes(countryId)) return;
		let now = new Date().toJSON();
		let userData = JSON.parse(JSON.stringify(rootState.auth.user.details.userData));
		let e = userData.supData.find(r => r.country === countryId);
		e.activityConfirmedOn = now;
		e.activityWarningsMailsSent = 0;
		await dispatch('auth/user/updateUserData', { userData }, { root: true });
		commit('setActivityConfirmedFor', countryId);
	},

	async updateAnimalkindsSold({ dispatch, rootState }, { animalkind, country }) {
		let userData = JSON.parse(JSON.stringify(rootState.auth.user.details.userData));
		let e = userData.supData.find(r => r.country === country);
		if (!e.animalkindsSold || !e.animalkindsSold.includes(animalkind)) {
			// eslint-disable-next-line no-negated-condition
			if (!e.animalkindsSold) e.animalkindsSold = [animalkind];
			else e.animalkindsSold.push(animalkind);
			await dispatch('auth/user/updateUserData', { userData }, { root: true });
		}
	},

	notifyMissingOffergroup(_, { name, animalkind }) {
		return notifyMissingOffergroup(name, animalkind);
	}


};

const mutations = {

	setEntityclass(state, { value }) {
		state.entityclass = value;
	},
	setAttributeset(state, { value }) {
		state.attributeset = value;
	},
	setActivityConfirmedFor(state, countryId) {
		state.activtyConfirmedFor.push(countryId);
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
