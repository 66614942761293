<template>
	<b-nav-item-dropdown
		v-if="country.data.languages.length > 1"
		:text="$t('navigation.language')"
		right
	>
		<b-dropdown-item
			v-for="(lang, i) in country.data.languages"
			:key="i + lang"
			@click="selectLang(lang)"
			:active="lang === $store.state.base.uiLocale"
		>
			{{ $t(`default.language.${lang}`) }}
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
/*
 * builds main menu from config
 * will be expanded to filter by user/role
 */
export default {
	name: 'LangSelect',
	components: {
	},
	data() {
		return {
		};
	},
	computed: {
		country() {
			return this.$store.getters['country/currentCountry'];
		},
	},
	methods: {
		selectLang(lang) {
			this.$store.commit('base/setUiLocale', lang);
		}
	}
};
</script>
