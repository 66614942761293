import { post } from '@/util/request.js';

export function getEmailVerificationStatus(email) {
	return post('mailer/verification/check', { email });
}

export function requestVerification(data) {
	return post('mailer/verification/request', data);
}

export function completeVerification(data) {
	return post('mailer/verification/complete', data);
}

export function ssoVerification(data) {
	return post('mailer/verification/sso', data);
}

export function templateList() {
	return post('mailer/template/list');
}

export function templateSet({ name, template, language, type }) {
	return post('mailer/template/set', { name, template, language, type });
}

export function templateGet({ template, language }) {
	return post('mailer/template/get', { template, language });
}
